import React from "react";
import { Container, Row, Col } from "reactstrap";
import "../../assets/css/footer.scss";

function DarkFooter() {
  return (
    <footer className="footer bg-black p-0">
      <Container className="py-5">

        <Row className="justify-content-center align-items-center py-3 text-center">
          <Col lg={12} md={10} sm={10} xs={10}>

            <Row className="justify-content-center align-items-top py-3 text-center">
              <Col lg={3} md={12} sm={12} xs={12}>
                <div className="m-3">
                  <img className="img-fluid" src={require("../../assets/img/navbar/logo2.png").default} />
                </div>
              </Col>
              <Col lg={3} md={12} sm={12} xs={12} className="">
              <div className="m-3 text-center text-lg-left">
                  <span className="paragraph mb-4 footerHeaderText">
                    CATX GLOBAL SDN. BHD.
                  </span> <br />
                  <span className="paragraph mb-4 footerContentText">
                    159, Jalan Templer, PJS 8, <br />
                    46050 Petaling Jaya, Selangor.
                  </span>
                </div>
              </Col>
              <Col lg={3} md={12} sm={12} xs={12} className="">
              <div className="m-3 text-center text-lg-left">
                  <span className="paragraph mb-4 footerHeaderText">
                    OPERATION HOURS:
                  </span> <br />
                  <span className="paragraph mb-4 footerContentText">
                    Mon-Fri (except public holiday) <br />
                    9.30AM - 6.30PM
                  </span>
                </div>
              </Col>
              <Col lg={3} md={12} sm={12} xs={12} >
              <div className="m-3 text-center text-lg-left">
                  <span className="paragraph mb-4 footerContentText">
                    +60 11-6257 2626 <br />
                    official@catx.global
                  </span>
                </div>
              </Col>
            </Row>
          </Col>
        </Row>
      </Container>
      <div className="bg-white py-4 text-center">
        <span className="paragraph mb-4 footerCopyrightText">
          CATX © 2022 | ALL RIGHTS RESERVED | POWERED BY CATSON GROUP SDN.BHD.
        </span>
      </div>
    </footer>
  );
}

export default DarkFooter;

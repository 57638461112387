import React from "react";
import { Container, Row, Col } from "reactstrap";
import "../assets/css/share2earn.scss";
import { FaAngleRight, FaSearch } from 'react-icons/fa';
import DarkFooter from "components/Footers/DarkFooter";
import IndexNavbar from "components/Navbars/IndexNavbar";
import Fade from 'react-reveal/Fade';
import Slide from 'react-reveal/Slide';
import Flip from 'react-reveal/Flip';
import { Zoom } from "react-reveal";

function Share2Earn() {

    React.useEffect(() => {
        document.body.classList.add("index-page");
        document.body.classList.add("sidebar-collapse");
        document.documentElement.classList.remove("nav-open");
        window.scrollTo(0, 0);
        document.body.scrollTop = 0;
        return function cleanup() {
            document.body.classList.remove("index-page");
            document.body.classList.remove("sidebar-collapse");
        };
    });

    return (
        <>
            <IndexNavbar />
            <div id="about" className="pt-5">
                <div className="bgBlack py-5">
                    <Row className="justify-content-center align-items-start w-100 h-100 text-center my-5 pt-5 ml-0 mr-0">
                        <Col lg={6} md={8} sm={12} xs={12}>
                            <div className="">
                                <Fade top duration={3000}>
                                    <div className='text-center mt-2'>
                                        <span className='about-titleText'>
                                            SHARE2EARN
                                        </span>
                                    </div>
                                    <div className="py-3">
                                        <img className="img-fluid headerArrow" src={require("../assets/img/about/arrow-down.png").default} />
                                    </div>
                                </Fade>
                                <div className="py-2">
                                    <div className="mt-4">
                                        <Fade duration={3000}>
                                            <div>
                                                <span className="share2earnTitle">Share2Earn</span> <br />
                                                <span className="share2earnSubtitle">Top Leading Sharing Platform.</span>
                                            </div>
                                        </Fade>
                                        <div className="mt-4">
                                            <span className="share2earnDetails">
                                                It’s time to enter a world of limitless possibilities with the Top Leading Sharing Platform. We have built a brand new business strategy that creates value through our sharing platform that is convenient and it enhances the experience for both distributors and customers through our platform. Provide consumers with the ability to earn rewards for their purchases.
                                            </span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </Col>
                    </Row>
                    <Row className="justify-content-center align-items-start pb-3 mb-3 ml-0 mr-0">
                        <Col lg={10} md={12} sm={12} xs={12}>
                            <Zoom duration={2000}>
                                <div className="py-3 text-center">
                                    {/* <img className="img-fluid" src={require("../assets/img/share2earn/header_bg.png").default} /> */}
                                    <video autoPlay={true} muted loop playsInline className="w-100">
                                        <source src={require("../assets/img/share2earn/globe.mp4").default} type="video/mp4" />
                                    </video>
                                </div>
                            </Zoom>
                        </Col>
                    </Row>
                </div>

                <div className="container-fluid missionBg py-5 position-relative">
                    <Container className="py-5 my-5">
                        <Row className="justify-content-center align-items-center">
                            <Col lg={10} md={10} sm={12} xs={12}>
                                <div className="text-center">
                                    <Fade top duration={2000}>
                                        <div>
                                            <span className="paragraph mb-4 text-white missionText">
                                                Mission
                                            </span> <br /><br />
                                        </div>
                                    </Fade>
                                    <Fade bottom duration={2000}>
                                        <div>
                                            <span className="paragraph mb-4 missionDescText1">
                                                Create a platform that facilitates
                                                <span className="paragraph mb-4 ml-2 missionDescText2">
                                                    greater profit
                                                </span> <br />
                                                for more people in this
                                                <span className="paragraph mb-4 ml-2 missionDescText2">
                                                    inflation era.
                                                </span>
                                            </span>
                                        </div>
                                    </Fade>
                                </div>
                            </Col>
                        </Row>
                    </Container>
                </div>

                <div className="container-fluid bonusBg py-5">
                    <Container className="py-5">
                        <Row className="justify-content-center align-items-center">
                            <Col lg={8} md={10} sm={12} xs={12}>
                                <Zoom duration={3000}>
                                    <Row className="justify-content-center align-items-start">
                                        <Col lg={12} md={12} sm={12} xs={12}>
                                            <div className="text-center container py-3">
                                                <div className="m-3">
                                                    <span className="bonusTitle">
                                                        Active Bonus
                                                    </span> <br /><br />
                                                    <span className="bonusDetails">
                                                        Create an income strategy. Make money, progress and growth.
                                                    </span>
                                                </div>
                                            </div>
                                        </Col>
                                        <Col lg={12} md={12} sm={12} xs={12}>
                                            <div className="text-center container py-3">
                                                <div className="m-3">
                                                    <span className="bonusTitle">
                                                        Passive Bonus
                                                    </span> <br /><br />
                                                    <span className="bonusDetails">
                                                        Generate income without having to exchange more hours. Build multiple streams of bonuses by leveraging off all your resources to generate unlimited income.
                                                    </span>
                                                </div>
                                            </div>
                                        </Col>
                                            <div className="text-center pt-3">
                                                <div className="infoBtn">
                                                    <div className="d-flex">
                                                        <span className="infoBtnText">share2earn.com</span> &nbsp;&nbsp;
                                                        <span className="infoBtnText infoBtnIcon"><FaSearch /></span>
                                                    </div>
                                                </div>
                                            </div>
                                        <Col lg={12} md={12} sm={12} xs={12}>
                                            <div className="text-center pt-5">
                                                <div className="container">
                                                    <a className="hoverEffect-dropdown" href="https://member.catx.global/Member/Login" target="_blank">
                                                        <span className="memberLoginText">MEMBER LOGIN</span>
                                                    </a>
                                                </div>
                                            </div>
                                        </Col>
                                    </Row>
                                </Zoom>
                            </Col>
                        </Row>
                    </Container>
                </div>


            </div>
            <DarkFooter />
        </>
    );
}

export default Share2Earn;

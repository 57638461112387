import React from "react";
import { Container, Row, Col, Input } from "reactstrap";
import "../assets/css/contactus.scss";
import { FaAngleRight } from 'react-icons/fa';
import IndexNavbar from "components/Navbars/IndexNavbar";
import DarkFooter from "components/Footers/DarkFooter";
import Fade from 'react-reveal/Fade';
import swal from 'sweetalert';

function ContactUs() {
    React.useEffect(() => {
        document.body.classList.add("index-page");
        document.body.classList.add("sidebar-collapse");
        document.documentElement.classList.remove("nav-open");
        window.scrollTo(0, 0);
        document.body.scrollTop = 0;
        return function cleanup() {
            document.body.classList.remove("index-page");
            document.body.classList.remove("sidebar-collapse");
        };
    });

    const [formData, setFormData] = React.useState({
        name: "",
        email: "",
        company: "",
        number: "",
        message: "",
    })

    const [error, setError] = React.useState({
        name: "",
        email: "",
        company: "",
        number: "",
        message: "",
    })

    const handleAddFormChange = (event) => {
        event.preventDefault();
        const fieldName = event.target.getAttribute('name');
        const fieldValue = event.target.value;
        const newFormData = { ...formData };
        newFormData[fieldName] = fieldValue;
        setFormData(newFormData);
    };

    const sendForm = () => {
        let check = true;
        let errorMsg = { name: "", email: "", company:"", number:"", message: "" }
        let reg = /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/;
        let reg2 = /^[0-9]+$/;
        let reg3 = /^[A-Za-z\s]*$/;

        if (!formData.name || formData.name === "") {
            errorMsg.name = "This field is required.";
            check = false;
        }
        else {
            if (!reg3.test(formData.name)) {
                check = false;
                errorMsg.name = "Invalid Name Format";
            }
        }

        if (!formData.email || formData.email === "") {
            errorMsg.email = "This field is required.";
            check = false;
        } else {
            if (!reg.test(formData.email)) {
                check = false;
                errorMsg.email = "Invalid Email Format";
            }
        }

        if (!formData.number || formData.number === "") {
            errorMsg.number = "This field is required.";
            check = false;
        } else {
            if (!reg2.test(formData.number)) {
                check = false;
                errorMsg.number = "Invalid Phone Number Format";
            }
        }

        if (!formData.message || formData.message === "") {
            errorMsg.message = "This field is required.";
            check = false;
        }
        setError(errorMsg)
        if (check) {
            let bodyForm = {
                "name": formData.name,
                "email": formData.email,
                "company": formData.company,
                "number": formData.number,
                "message": formData.message
            }
            console.log('bodyForm', bodyForm);
            fetch("https://mailer.catx.bgzone.asia/sendmail", {
                method: 'POST',
                headers: new Headers({
                    "Content-Type": "application/JSON",
                }),
                body: JSON.stringify(bodyForm),
            }).then(response => response.json())
                .then(responseJson => {
                    // alert('Message sent!');
                    swal("Thank You!", "Message has been sent successfully!", "success");
                    setFormData({
                        name: "",
                        email: "",
                        company: "",
                        number: "",
                        message: "",
                    })
                })
                .catch(error => {
                    console.error(error);
                });
        }
    }

    return (
        <>
            <IndexNavbar />
            <div id="contactUs" className="pb-5 mt-3">
                <div className="container-fluid py-5">
                    <Container className="">
                        <Row className="justify-content-center align-items-start">
                            <Col lg={12} md={12} sm={12} xs={12}>
                                <Fade top duration={3000}>
                                    <div className="pt-5 mt-5 text-center">
                                        <span className="paragraph text-black visionText">
                                            CONTACT US
                                        </span>
                                        <br />
                                        <img className="img-fluid headerArrow" src={require("../assets/img/academy/arrow-down.png").default} /> <br />
                                    </div>
                                </Fade>
                                <Row className="pt-5 justify-content-center align-items-start">
                                    <Col lg={10} md={12} sm={12} xs={12} className="mb-5">
                                        <div className="">
                                            <Row className="justify-content-center align-items-start">
                                                <Col lg={6} md={6} sm={12} xs={12}>
                                                    <div className="m-2">
                                                        <span className="inputTitle">FULL NAME</span> <br />
                                                        <Input
                                                            placeholder="Your Name"
                                                            type="text"
                                                            name="name"
                                                            className="contactUs-textBox"
                                                            value={formData.name}
                                                            onChange={handleAddFormChange}
                                                        />
                                                        <span className="error">{error.name}</span>
                                                    </div>
                                                </Col>
                                                <Col lg={6} md={6} sm={12} xs={12}>
                                                    <div className="m-2">
                                                        <span className="inputTitle">E-MAIL</span> <br />
                                                        <Input
                                                            placeholder="Your Email"
                                                            type="text"
                                                            name="email"
                                                            className="contactUs-textBox"
                                                            value={formData.email}
                                                            onChange={handleAddFormChange}
                                                        />
                                                        <span className="error">{error.email}</span>
                                                    </div>
                                                </Col>
                                                <Col lg={6} md={6} sm={12} xs={12}>
                                                    <div className="m-2">
                                                        <span className="inputTitle">COMPANY</span> <br />
                                                        <Input
                                                            placeholder="e.g: Apple"
                                                            type="text"
                                                            name="company"
                                                            className="contactUs-textBox"
                                                            value={formData.company}
                                                            onChange={handleAddFormChange}
                                                        />
                                                        <span className="error">{error.company}</span>
                                                    </div>
                                                </Col>
                                                <Col lg={6} md={6} sm={12} xs={12}>
                                                    <div className="m-2">
                                                        <span className="inputTitle">PHONE NUMBER</span> <br />
                                                        <Input
                                                            placeholder="e.g: 011550xxxxx"
                                                            type="text"
                                                            name="number"
                                                            className="contactUs-textBox"
                                                            value={formData.number}
                                                            onChange={handleAddFormChange}
                                                        />
                                                        <span className="error">{error.number}</span>
                                                    </div>
                                                </Col>
                                                <Col lg={12} md={12} sm={12} xs={12}>
                                                    <div className="m-2">
                                                        <span className="inputTitle">WHAT'S ON YOUR MIND?</span> <br />
                                                        <Input
                                                            placeholder=""
                                                            type="textarea"
                                                            rows="10"
                                                            name="message"
                                                            className="contactUs-textArea"
                                                            value={formData.message}
                                                            onChange={handleAddFormChange}
                                                        />
                                                        <span className="error">{error.message}</span>
                                                    </div>
                                                </Col>
                                            </Row>
                                            <Row className="justify-content-end align-items-start pt-3">
                                                <div className="sendBtn bgBlack" onClick={()=>sendForm()}>
                                                    <span className="inputTitle text-white">SEND</span>
                                                </div>
                                            </Row>
                                        </div>
                                    </Col>
                                </Row>
                            </Col>

                        </Row>
                    </Container>
                </div>
            </div>
            <DarkFooter />
        </>
    );
}

export default ContactUs;

import React from "react";
import { Container, Row, Col } from "reactstrap";
import "../assets/css/about.scss";
import { FaAngleRight } from 'react-icons/fa';
import DarkFooter from "components/Footers/DarkFooter";
import IndexNavbar from "components/Navbars/IndexNavbar";
import Fade from 'react-reveal/Fade';
import Slide from 'react-reveal/Slide';
import Flip from 'react-reveal/Flip';
import { Zoom } from "react-reveal";

function AboutUs() {
    React.useEffect(() => {
        document.body.classList.add("index-page");
        document.body.classList.add("sidebar-collapse");
        document.documentElement.classList.remove("nav-open");
        window.scrollTo(0, 0);
        document.body.scrollTop = 0;
        return function cleanup() {
            document.body.classList.remove("index-page");
            document.body.classList.remove("sidebar-collapse");
        };
    });

    return (
        <>
            <IndexNavbar />
            <div id="about">
                <div className="outer-aboutContainer py-5 my-5">
                    <Row className="justify-content-center align-items-start w-100 h-100 text-center pt-5 ml-0 mr-0">
                        <Col lg={10} md={10} sm={12} xs={12}>
                            <div className="">
                                <Fade top duration={3000}>
                                    <div className='text-center mt-2'>
                                        <span className='about-titleText'>
                                            ABOUT US
                                        </span>
                                    </div>
                                    <div className="py-3">
                                        <img className="img-fluid headerArrow" src={require("../assets/img/about/arrow-down.png").default} />
                                    </div>
                                </Fade>
                            </div>
                            {/* <div className="container-fluid aboutHeaderBg py-5">
                                <Container className="py-5 about-header-container text-center">
                                    <Row className="justify-content-center align-items-center w-100 h-100 text-center">
                                        <Col lg={12} md={12} sm={12} xs={12}>
                                            <img className="img-fluid mb-1" src={require("../assets/img/about/logo.png").default} /> <br />
                                        </Col>
                                    </Row>
                                </Container>
                            </div> */}

                        </Col>
                    </Row>
                    <Row className="justify-content-center align-items-start pb-5  ml-0 mr-0">

                        <Col lg={12} md={12} sm={12} xs={12}>
                            <Zoom duration={3000}>
                                <div className="text-center">
                                    <div className="pt-5 text-center">
                                        <img className="img-fluid" src={require("../assets/img/about/about_headerBg2.png").default} />
                                    </div>
                                </div>
                            </Zoom>
                        </Col>
                    </Row>
                </div>

                <div className="container-fluid aboutVisionBg py-2 pb-5">
                    <Container className="py-5 text-center">
                        <Row className="justify-content-center align-items-center text-center pb-3">
                            <Col lg={10} md={10} sm={12} xs={12}>
                                <Row className="justify-content-center align-items-start">
                                    <Col lg={8} md={10} sm={12} xs={12} className="py-3">
                                        <Fade top duration={2000}>
                                            <div className="py-3">
                                                <span className="paragraph mb-4 text-black visionHeader">Vision</span> <br /><br />
                                                <span className="paragraph mb-4 text-black visionContents">Building Futures, Through Blockchain</span> <br />
                                            </div>
                                        </Fade>
                                    </Col>
                                    <Col lg={8} md={10} sm={12} xs={12} className="py-3">
                                        <Fade duration={2000}>
                                            <div className="py-3">
                                                <span className="paragraph mb-4 text-black visionHeader">Mission</span> <br /><br />
                                                <span className="paragraph mb-4 text-black visionContents">Creating innovative investment opportunities for everyone through our ecosystem with blockchain.</span> <br />
                                            </div>
                                        </Fade>
                                    </Col>
                                    <Col lg={8} md={10} sm={12} xs={12} className="py-3">
                                        <Fade bottom duration={2000}>
                                            <div className="py-3">
                                                <span className="paragraph mb-4 text-black visionHeader">Core Value</span> <br /><br />
                                                <span className="paragraph mb-4 text-black visionContents">Purpose, Transparency, and Digital Ownership</span> <br />
                                            </div>
                                        </Fade>
                                    </Col>
                                </Row>
                            </Col>
                        </Row>
                    </Container>
                </div>

                <div className="blackBg py-5">
                    <Row className="justify-content-center align-items-start h-100 text-center ml-0 mr-0">
                        <Col lg={6} md={8} sm={12} xs={12} className="aboutBottomBg">
                            <div className="pt-5">
                                <div className='text-center mt-2'>
                                    <span className="paragraph mb-4 text-white rulesHeaderText">Our Culture </span>
                                </div>
                                <div className="py-3">
                                    <span className="text-white rulesHeaderDesc">A strong team, proven technology and a strong commitment to security, market integrity and performance. Institutionalise the space, and promote the growth and advancement of the underlying ecosystem and technology behind digital assets and blockchain.</span> <br />
                                </div>
                            </div>
                        </Col>
                        <Col lg={12} md={12} sm={12} xs={12}>
                            <Zoom duration={3000}>
                                <div className="text-center">
                                    <div className="py-5">
                                        <img className="img-fluid" src={require("../assets/img/about/culture_bg.png").default} />
                                    </div>
                                </div>
                            </Zoom>
                        </Col>
                    </Row>
                </div>
                <div className="greyBg2 py-5">
                    <Row className="justify-content-center align-items-start h-100 text-center ml-0 mr-0">
                        <Col lg={6} md={8} sm={12} xs={12} className="aboutBottomBg">
                            <div className="pt-5">
                                <div className='text-center mt-2'>
                                    <span className="paragraph mb-4 text-white rulesHeaderText">Our Principle </span>
                                </div>
                                <div className="py-3">
                                    <span className="text-white rulesHeaderDesc">Our principles are what we stand for. They are beliefs we hold deeply and make tradeoffs to pursue.The experiences we build are centred around transparency, control, inclusivity and empowerment  for a global economy built on blockchains. </span><br />
                                </div>
                            </div>
                        </Col>
                        <Col lg={12} md={12} sm={12} xs={12}>
                            <Zoom duration={3000}>
                                <div className="text-center">
                                    <div className="py-5">
                                        <img className="img-fluid" src={require("../assets/img/about/principle_bg.png").default} />
                                    </div>
                                </div>
                            </Zoom>
                        </Col>
                    </Row>
                </div>

            </div>
            <DarkFooter />
        </>
    );
}

export default AboutUs;

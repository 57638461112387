import React from "react";
import { Container, Row, Col } from "reactstrap";
import "../assets/css/academy.scss";
import { FaAngleRight } from 'react-icons/fa';
import IndexNavbar from "components/Navbars/IndexNavbar";
import DarkFooter from "components/Footers/DarkFooter";
import Fade from 'react-reveal/Fade';
import Flip from 'react-reveal/Flip';
import { Zoom } from "react-reveal";
import Slide from 'react-reveal/Slide';

function Academy() {
    React.useEffect(() => {
        document.body.classList.add("index-page");
        document.body.classList.add("sidebar-collapse");
        document.documentElement.classList.remove("nav-open");
        window.scrollTo(0, 0);
        document.body.scrollTop = 0;
        return function cleanup() {
            document.body.classList.remove("index-page");
            document.body.classList.remove("sidebar-collapse");
        };
    });

    return (
        <>
            <IndexNavbar />
            <div id="academy" className="pb-5 mb-3">
                <div className="container-fluid py-5">
                    <Container className="">
                        <Row className="justify-content-center align-items-start home-header-container text-center">
                            <Col lg={8} md={8} sm={12} xs={12}>
                                <div className="">
                                    <Fade top duration={3000}>

                                        <div className="text-center">
                                            <span className="paragraph mb-4 text-black visionText">
                                                CATX ACADEMY
                                            </span> <br />
                                            <img className="img-fluid headerArrow" src={require("../assets/img/academy/arrow-down.png").default} /> <br />
                                        </div>
                                    </Fade>
                                    <Slide bottom duration={3000}>
                                        <div className="pt-2 text-center">
                                            <img className="img-fluid" src={require("../assets/img/academy/logo.png").default} /> <br />
                                        </div>
                                    </Slide>
                                    <div className="pt-5 text-center">
                                        <span className="paragraph mb-4 text-black academyDesc">To assist our community in the adoption of the blockchain by creating a platform for everyone to learn, understand, and be fully equipped with the right knowledge as we enter the future of this new technology. Stay tuned for more!
                                        </span>
                                    </div>
                                </div>
                            </Col>
                            <Col lg={12} md={10} sm={12} xs={12}>
                                <Zoom duration={3000}>
                                    <div className="">
                                        <div className="pt-5">
                                            <img className="img-fluid w-100" src={require("../assets/img/academy/header-img.png").default} /> <br />
                                        </div>
                                    </div>
                                </Zoom>
                            </Col>
                        </Row>
                    </Container>
                </div>
            </div>
            <DarkFooter />
        </>
    );
}

export default Academy;

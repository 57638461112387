import React from "react";
import { FaAngleDown } from "react-icons/fa";
import { Link } from "react-router-dom";
import {
  Button,
  Collapse,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  UncontrolledDropdown,
  NavbarBrand,
  Navbar,
  NavItem,
  NavLink,
  Nav,
  Container,
  UncontrolledTooltip,
} from "reactstrap";
import "../../assets/css/navbar.scss";

function IndexNavbar() {
  const [navbarColor, setNavbarColor] = React.useState("bg-black");
  const [collapseOpen, setCollapseOpen] = React.useState(false);
  React.useEffect(() => {
    const updateNavbarColor = () => {
      if (
        document.documentElement.scrollTop > 399 ||
        document.body.scrollTop > 399
      ) {
        setNavbarColor("bg-black");
      } else if (
        document.documentElement.scrollTop < 400 ||
        document.body.scrollTop < 400
      ) {
        setNavbarColor("bg-black");
      }
    };
    window.addEventListener("scroll", updateNavbarColor);
    return function cleanup() {
      window.removeEventListener("scroll", updateNavbarColor);
    };
  });
  return (
    <>
      {collapseOpen ? (
        <div
          id="bodyClick"
          onClick={() => {
            document.documentElement.classList.toggle("nav-open");
            setCollapseOpen(false);
          }}
        />
      ) : null}
      <Navbar className={"fixed-top " + navbarColor} expand="lg">
        <Container className="pt-3 pb-3" style={{ maxWidth: "100%" }}>
          <div className="navbar-translate">
            <NavbarBrand href="/index" id="navbar-brand">
              <img alt="logo" className="img-fluid navbarLogo" src={require("../../assets/img/navbar/logo.png").default} />
            </NavbarBrand>
            <button
              className="navbar-toggler navbar-toggler"
              onClick={() => {
                document.documentElement.classList.toggle("nav-open");
                setCollapseOpen(!collapseOpen);
              }}
              aria-expanded={collapseOpen}
              type="button"
            >
              <span className="navbar-toggler-bar top-bar"></span>
              <span className="navbar-toggler-bar middle-bar"></span>
              <span className="navbar-toggler-bar bottom-bar"></span>
            </button>
          </div>
          <Collapse
            className="justify-content-start ml-2 pl-2 ml-lg-5 pl-lg-5"
            isOpen={collapseOpen}
            navbar
          >
            <Nav navbar>
              <NavLink href="/about">
                <NavItem>
                  <span className="navbarText">ABOUT</span>
                </NavItem>
              </NavLink>

              <UncontrolledDropdown nav>
                <DropdownToggle nav >
                  <span className="navbarText">OMO</span>
                  <FaAngleDown className="dropdownIcon ml-1" />
                </DropdownToggle>
                <DropdownMenu className="bg-black">
                  <a className="hoverEffect-dropdown" href="/product-and-brand">
                    <DropdownItem>
                      <span className="navbarsubText">PRODUCT & BRAND</span>
                    </DropdownItem>
                  </a>
                  <a className="hoverEffect-dropdown" href="/share2earn">
                    <DropdownItem >
                      <span className="navbarsubText">SHARE 2 EARN</span>
                    </DropdownItem>
                  </a>
                  <a className="hoverEffect-dropdown" href="/academy">
                    <DropdownItem>
                      <span className="navbarsubText">CATX ACADEMY</span>
                    </DropdownItem>
                  </a>
                  <a className="hoverEffect-dropdown" href="/tokenomics">
                    <DropdownItem>
                      <span className="navbarsubText">TOKENOMICS</span>
                    </DropdownItem>
                  </a>
                  <a className="hoverEffect-dropdown" href="/NFT">
                    <DropdownItem>
                      <span className="navbarsubText">NON FUNGIBLE TOKEN (NFT)</span>
                    </DropdownItem>
                  </a>
                </DropdownMenu>
              </UncontrolledDropdown>
              <NavLink href="/cube">
                <NavItem>
                  <img className="img-fluid" src={require("../../assets/img/navbar/cube-logo.png").default} />
                </NavItem>
              </NavLink>

              <NavLink href="/news-and-events">
                <NavItem>
                  <span className="navbarText">NEWS & EVENTS</span>
                </NavItem>
              </NavLink>
              <NavLink href="/contact-us">

                <NavItem>
                  <span className="navbarText">CONTACT US</span>
                </NavItem>
              </NavLink>

              <NavItem>
                <div className="navLoginBtn2 d-block d-lg-none">
                  <a className="hoverEffect-dropdown" href="https://member.catx.global/Member/Login" target="_blank">
                    <img className="img-fluid loginLogo" src={require("../../assets/img/navbar/login_btn.png").default} />
                  </a>
                </div>

                <div className="navLoginBtn d-none d-lg-block">
                  <a className="hoverEffect-dropdown" href="https://member.catx.global/Member/Login" target="_blank">
                    <img className="img-fluid loginLogo loginLogo2" src={require("../../assets/img/navbar/login_btn.png").default} />
                    <span className="navbarText loginText">SHARE2EARN LOGIN</span>
                  </a>
                </div>
              </NavItem>

            </Nav>
          </Collapse>
        </Container>
      </Navbar>
    </>
  );
}

export default IndexNavbar;

import React from "react";
import { Container, Row, Col } from "reactstrap";
import "../assets/css/tokenomics.scss";
import { FaAngleRight } from 'react-icons/fa';
import DarkFooter from "components/Footers/DarkFooter";
import IndexNavbar from "components/Navbars/IndexNavbar";
import Fade from 'react-reveal/Fade';
import { Zoom } from "react-reveal";

function Tokenomic() {

    React.useEffect(() => {
        document.body.classList.add("index-page");
        document.body.classList.add("sidebar-collapse");
        document.documentElement.classList.remove("nav-open");
        window.scrollTo(0, 0);
        document.body.scrollTop = 0;
        return function cleanup() {
            document.body.classList.remove("index-page");
            document.body.classList.remove("sidebar-collapse");
        };
    });

    return (
        <>
            <IndexNavbar />
            <div id="about" className="pt-5">
                <div className="outer-nftContainer py-5">
                    <Row className="justify-content-center align-items-start w-100 h-100 text-center my-5 pt-5 ml-0 mr-0">
                        <Col lg={6} md={8} sm={12} xs={12}>
                            <div className="">
                                <Fade top duration={3000}>
                                    <div className='text-center mt-2'>
                                        <span className='about-titleText'>
                                            TOKENOMICS
                                        </span>
                                    </div>
                                    <div className="py-3">
                                        <img className="img-fluid headerArrow" src={require("../assets/img/about/arrow-down.png").default} />
                                    </div>
                                </Fade>
                                <div className="py-2">
                                    <div className="mt-4">
                                        <span className="nftDesc">
                                            It is designed to create new markets and monetization methods through our community app. To make a pool of content within our ecosystem that is self-sustaining while keeping the liquidity pool strong.
                                        </span>
                                    </div>
                                </div>
                            </div>
                        </Col>
                    </Row>

                    <Row className="justify-content-center align-items-start pb-3  ml-0 mr-0">
                        <Col lg={10} md={12} sm={12} xs={12}>
                            <Zoom duration={2000}>
                                <div className="text-center">
                                    <div className="py-5 my-5 text-center coin1Bg">
                                        <Row className="justify-content-center align-items-center">
                                            <Col lg={12} md={11} sm={11} xs={11}>
                                                <span className="paragraph mb-1 text-white coin-title">CAT COIN</span>
                                                <br />
                                                <span className="text-white coin-subtitle">
                                                    Governance Token
                                                </span> <br />
                                                <div className="pt-3 pb-5">
                                                    <span className="py-3 supplyBox">
                                                        <span className="text-white coin-supply p-3">
                                                            Total Supply: 2.6Billions
                                                        </span>
                                                    </span>
                                                    <br />
                                                </div>
                                                <img className="img-fluid pb-5 mb-5" src={require("../assets/img/tokenomics/cat-coin1.png").default} />
                                            </Col>
                                        </Row>
                                    </div>
                                </div>
                            </Zoom>
                        </Col>
                    </Row>

                    <Row className="justify-content-center align-items-start pb-3  ml-0 mr-0">
                        <Col lg={10} md={12} sm={12} xs={12}>
                            <Zoom duration={2000}>
                                <div className="text-center">
                                    <div className="py-5 my-5 text-center coin2Bg">
                                        <Row className="justify-content-center align-items-center">
                                            <Col lg={6} md={8} sm={11} xs={11}>
                                                <span className="paragraph mb-1 text-white coin-title">FOX COIN</span>
                                                <br />
                                                <span className="text-white coin-subtitle">
                                                    Utility Token
                                                </span> <br />
                                                <span className="text-white coin-details">
                                                    A utility token can be defined as a blockchain token that provides access to a company's product or service.
                                                </span> <br /><br />
                                                <div className="pt-3 pb-5">
                                                    <span className="py-3 supplyBox">
                                                        <span className="text-white coin-supply p-3">
                                                            Unlimited Supply
                                                        </span>
                                                    </span>
                                                    <br />
                                                </div>
                                            </Col>
                                            <Col lg={12} md={11} sm={11} xs={11}>
                                                <img className="img-fluid pb-5 mb-5" src={require("../assets/img/tokenomics/fox-coin1.png").default} />

                                            </Col>
                                        </Row>
                                    </div>
                                </div>
                            </Zoom>
                        </Col>
                    </Row>
                </div>
            </div>
            <DarkFooter />
        </>
    );
}

export default Tokenomic;

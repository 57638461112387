import React from "react";
import { Container, Row, Col } from "reactstrap";
import "../assets/css/nft.scss";
import { FaAngleRight } from 'react-icons/fa';
import DarkFooter from "components/Footers/DarkFooter";
import IndexNavbar from "components/Navbars/IndexNavbar";
import Fade from 'react-reveal/Fade';
import Slide from 'react-reveal/Slide';
import Flip from 'react-reveal/Flip';
import { Zoom } from "react-reveal";

function NFT() {

    React.useEffect(() => {
        document.body.classList.add("index-page");
        document.body.classList.add("sidebar-collapse");
        document.documentElement.classList.remove("nav-open");
        window.scrollTo(0, 0);
        document.body.scrollTop = 0;
        return function cleanup() {
            document.body.classList.remove("index-page");
            document.body.classList.remove("sidebar-collapse");
        };
    });

    return (
        <>
            <IndexNavbar />
            <div id="nft">
                <div className="outer-nftContainer py-5">
                    <Row className="justify-content-center align-items-start w-100 h-100 text-center my-5 pt-5 ml-0 mr-0">
                        <Col lg={6} md={6} sm={12} xs={12}>
                            <div className="">
                                <Fade top duration={3000}>
                                    <div className='text-center mt-2'>
                                        <span className='about-titleText'>
                                            NFT
                                        </span>
                                    </div>
                                    <div className="py-3">
                                        <img className="img-fluid headerArrow" src={require("../assets/img/about/arrow-down.png").default} />
                                    </div>
                                </Fade>
                                <div className="pt-2">
                                    <Zoom duration={2000}>
                                        <div>
                                            <img className="img-fluid mb-1" src={require("../assets/img/nft/foxcat-logo.png").default} /> <br />
                                        </div>
                                    </Zoom>
                                    <div className="mt-4">
                                        <span className="nftDesc">With a total of 666 Genesis NFTs, holders will receive life-time
                                            membership benefits, free airdrops, whitelist, VIP events and investment
                                            opportunities within the CATX ecosystem.
                                        </span>
                                    </div>
                                </div>

                            </div>

                        </Col>
                    </Row>
                    <Row className="justify-content-center align-items-start pb-5  ml-0 mr-0">
                        <Col lg={12} md={12} sm={12} xs={12}>
                            <Zoom duration={3000}>
                                <div className="text-center">
                                    <div className="pt-5 text-center">
                                        <img className="img-fluid" src={require("../assets/img/nft/nft_bg2.png").default} />
                                    </div>
                                </div>
                            </Zoom>
                        </Col>
                    </Row>
                </div>
                {/* 
                <div className="position-relative">
                    <img src={require("../assets/img/nft/nft_bg.png").default} className="img-fluid w-100" />
                    <img src={require("../assets/img/nft/button_genesis.png").default} className="img-fluid button-img3" />
                    <img src={require("../assets/img/nft/button_utility.png").default} className="img-fluid button-img4" />
                </div> */}

                <div className="blackBg py-5">
                    <Row className="justify-content-center align-items-start h-100 text-center ml-0 mr-0">
                        <Col lg={6} md={8} sm={12} xs={12} className="">
                            <Slide top duration={2000}>
                                <div className="pt-5">
                                    <div className='text-center mt-2'>
                                        <span className="paragraph mb-4 text-white NFT-title">Genesis NFT</span>
                                    </div>
                                    <div className="py-2">
                                        <span className="text-white NFTDesc">
                                            666 Pieces <br />
                                            1-on-1 Hand Drawn
                                        </span> <br />
                                    </div>
                                </div>
                            </Slide>
                        </Col>
                        <Col lg={12} md={12} sm={12} xs={12}>
                            <Zoom duration={2000}>
                                <div className="text-center">
                                    <div className="py-5">
                                        <img className="img-fluid" src={require("../assets/img/nft/genesisBg.png").default} />
                                    </div>
                                </div>
                            </Zoom>
                        </Col>
                    </Row>
                    <Row className="justify-content-center align-items-start h-100 text-center ml-0 mr-0">
                        <Col lg={6} md={8} sm={12} xs={12} className="">
                            <Slide top duration={2000}>
                                <div className="pt-5">
                                    <div className='text-center mt-2'>
                                        <span className="paragraph mb-4 text-white NFT-title">Utility NFT</span>
                                    </div>
                                    <div className="py-2">
                                        <span className="text-white NFTDesc">
                                            Access to earn in <br />
                                            CUBE Social App
                                        </span> <br />
                                    </div>
                                </div>
                            </Slide>
                        </Col>
                        <Col lg={12} md={12} sm={12} xs={12}>
                            <Zoom duration={2000}>
                                <div className="text-center">
                                    <div className="py-5">
                                        <img className="img-fluid" src={require("../assets/img/nft/utilityBg.png").default} />
                                    </div>
                                </div>
                            </Zoom>
                        </Col>
                    </Row>
                </div>




                <div className="container-fluid greyBg py-5">
                    <Container className="py-5 text-center">
                        <div className="text-center pb-5">
                            <span className="socialMediaTitle">MORE TO DISCOVER</span>
                        </div>
                        <Row className="justify-content-center align-items-center">
                            <Col lg={5} md={12} sm={12} xs={12} className="text-right">
                                <Zoom duration={2000}>
                                    <div className="socialMediaBox bgBlack mb-4">
                                        <a href="https://discord.gg/qkzgcmwrWe" target="_blank" className="hoverEffect-dropdown">
                                            <Row className="justify-content-center align-items-center">
                                                <Col lg={3} md={3} sm={3} xs={3} className="text-right">
                                                    <img className="img-fluid mb-1" src={require("../assets/img/nft/discord.png").default} />
                                                </Col>
                                                <Col lg={7} md={7} sm={7} xs={7} className="text-left">
                                                    <span className="text-white socialMediaText">
                                                        Join our Discord now!
                                                    </span>
                                                </Col>
                                                <Col lg={2} md={2} sm={2} xs={2} className="text-left">
                                                    <img className="img-fluid mb-1" src={require("../assets/img/nft/socialmedia_icon.png").default} />
                                                </Col>
                                            </Row>
                                        </a>
                                    </div>
                                </Zoom>
                            </Col>

                            <Col lg={1} md={12} sm={12} xs={12} className="text-right" />

                            <Col lg={5} md={12} sm={12} xs={12} className="text-right">
                                <Zoom duration={2000}>
                                    <div className="socialMediaBox bgBlack mb-4">
                                        <a href="https://twitter.com/cubesocialapp" target="_blank" className="hoverEffect-dropdown">
                                            <Row className="justify-content-center align-items-center">
                                                <Col lg={3} md={3} sm={3} xs={3} className="text-left">
                                                    <img className="img-fluid mb-1" src={require("../assets/img/nft/twitter.png").default} />
                                                </Col>
                                                <Col lg={7} md={7} sm={7} xs={7} className="text-left">
                                                    <span className="text-white socialMediaText">
                                                        Follow our Twitter now!
                                                    </span>
                                                </Col>
                                                <Col lg={2} md={2} sm={2} xs={2} className="text-left">
                                                    <img className="img-fluid mb-1" src={require("../assets/img/nft/socialmedia_icon.png").default} />
                                                </Col>
                                            </Row>
                                        </a>
                                    </div>
                                </Zoom>
                            </Col>
                        </Row>
                    </Container>
                </div>
            </div>
            <DarkFooter />
        </>
    );
}

export default NFT;

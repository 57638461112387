import React from "react";
import { Container, Row, Col } from "reactstrap";
import "../assets/css/newsevent.scss";
import { FaAngleRight } from 'react-icons/fa';
import DarkFooter from "components/Footers/DarkFooter";
import IndexNavbar from "components/Navbars/IndexNavbar";
import moment from "moment";
import { Carousel } from 'react-responsive-carousel';
import "react-responsive-carousel/lib/styles/carousel.min.css";
import Fade from 'react-reveal/Fade';
import Slide from 'react-reveal/Slide';
import Flip from 'react-reveal/Flip';
import { Zoom } from "react-reveal";

const newsData = [
    { img: require('../assets/img/newsandevent/event1.png').default, date: '2022-04-15', title: 'Top Entrepreneurs Summit', venue: '', time: '', description: 'Top Entrepreneur Summit is a meeting of minds and Thought  to discuss the key trends, strategic challenges and sharing of experience, insights and knowledge to build back better and be updated on challenges, risks and opportunities.' },
    { img: require('../assets/img/newsandevent/event2.png').default, date: '2022-05-15', title: 'Your Future Asset Private Event', venue: '', time: '', description: 'CATX held an event on the Review of the Roadmap of CATX Tokenomics and #FOXORCAT NFT at W Hotel, Kuala Lumpur. Brings all of these stakeholders together in a highly interactive format to network, forge partnerships, deepen relationships, explore emerging trends, share best practices, and build the road ahead.' },
    { img: require('../assets/img/newsandevent/event3.png').default, date: '2022-06-16', title: 'CATX GRAND LAUNCHING', venue: '', time: '', description: 'CATX GRAND LAUNCHING was held at Malaysia Largest Indoor Stadium, Axiata Arena 16 June 2022 with talented singers and special performers Gary Chaw 曹格, 3P, and CATX founder Cat C.', desc2: 'Over 10,000 crowds to witness the success of CATX GRAND LAUNCHING. Combining the visual, hearing experience, and ambiance experience, we successfully brought our audiences to reach the highest peak of their excitement. ' },
    { img: require('../assets/img/newsandevent/event4.png').default, date: '2022-08-19', title: 'Malaysia Book of Records', venue: '', time: '', description: 'CATX GRAND LAUNCH (16 June 2022)  has set a record with the Malaysia Book of Records (MBR) for the LARGEST PARTICIPATION IN BUSINESS CONCEPT LAUNCHING 2022!' },
];

function NewsEvent() {

    React.useEffect(() => {
        document.body.classList.add("index-page");
        document.body.classList.add("sidebar-collapse");
        document.documentElement.classList.remove("nav-open");
        window.scrollTo(0, 0);
        document.body.scrollTop = 0;
        return function cleanup() {
            document.body.classList.remove("index-page");
            document.body.classList.remove("sidebar-collapse");
        };
    });

    return (
        <>
            <IndexNavbar />
            <div id="newsEvent" className="pt-5">
                <div className="bgBlack py-5">
                    <Row className="justify-content-center align-items-start w-100 h-100 text-center my-5 pt-5 ml-0 mr-0">
                        <Col lg={6} md={8} sm={12} xs={12}>
                            <div className="">
                                <Fade top duration={3000}>
                                    <div className='text-center mt-2'>
                                        <span className='about-titleText'>
                                            NEWS & EVENTS
                                        </span>
                                    </div>
                                    <div className="py-3">
                                        <img className="img-fluid headerArrow" src={require("../assets/img/about/arrow-down.png").default} />
                                    </div>
                                </Fade>
                                <div className="py-2">
                                    <div className="mt-4">
                                        <span className="nftDesc">
                                            Discover everything that is happening at CATX GLOBAL, from
                                            the latest news and events, to the most recent announcements
                                            and platform updates.
                                        </span>
                                    </div>
                                </div>
                            </div>
                        </Col>
                    </Row>
                    <Row className="justify-content-center align-items-start pb-3 mb-3 ml-0 mr-0">
                        <Col lg={10} md={12} sm={12} xs={12}>
                            <Zoom duration={3000}>
                                <div className="py-3 text-center">
                                    <img className="img-fluid" src={require("../assets/img/newsandevent/header_bg.png").default} />
                                </div>
                            </Zoom>
                        </Col>
                    </Row>
                </div>
                <div className="container-fluid grayBg eventListBg py-5">
                    <div className="container-fluid pb-5 pb-lg-0">
                        <Row className="py-3 justify-content-center align-items-center">
                            <Col xl={10} md={10} sm={12} xs={12} className="px-3">
                                {newsData.map((item, i) => (
                                    <>
                                        <Slide bottom duration={2000}>
                                            <Row key={i} className="pt-5 pb-5 mb-5">
                                                <Col xl={6} md={6} sm={12} xs={12} className="px-3">
                                                    <Carousel preventMovementUntilSwipeScrollTolerance={true} swipeable={true} showThumbs={false} autoPlay={true} infiniteLoop={true}>
                                                        <div className="mb-5">
                                                            <img src={item.img} className="img-fluid eventImg" />
                                                        </div>
                                                        <div className="mb-5">
                                                            <img src={item.img} className="img-fluid eventImg" />
                                                        </div>
                                                        <div className="mb-5">
                                                            <img src={item.img} className="img-fluid eventImg" />
                                                        </div>
                                                    </Carousel>
                                                </Col>
                                                <Col xl={6} md={6} sm={12} xs={12}>
                                                    <div className="mt-4 mt-lg-0 mt-md-0">
                                                        <span className="dateText">{moment(item.date).format('MMMM')} {moment(item.date).format('DD')}, {moment(item.date).format('YYYY')}</span> <br /> <br />
                                                        <span className="eventTitle">{item.title}</span> <br /><br />
                                                        <span className="eventDesc mt-3">{item.description}</span> <br /><br />
                                                        <span className="eventDesc mt-3">{item.desc2}</span> <br />

                                                        <span className="newsArrow d-none d-lg-block d-md-block"><img className="img-fluid" src={require("../assets/img/newsandevent/arrow-left.png").default} /></span>
                                                    </div>
                                                </Col>
                                            </Row>
                                        </Slide>
                                        <div className="divider my-3" style={{ display: i === newsData.length - 1 ? "none" : "block" }}></div>
                                    </>
                                ))}
                            </Col>
                        </Row>
                    </div>
                </div>
            </div>
            <DarkFooter />
        </>
    );
}

export default NewsEvent;

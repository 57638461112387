import React from "react";
import ReactDOM from "react-dom";
import { BrowserRouter, Route, Switch, Redirect } from "react-router-dom";

// styles for this kit
import "assets/css/bootstrap.min.css";
import "assets/scss/now-ui-kit.scss?v=1.5.0";
import "assets/demo/demo.css?v=1.5.0";
import "assets/demo/nucleo-icons-page-styles.css?v=1.5.0";
// pages for this kit
import Index from "views/Index.js";
import AboutUs from "CATX/AboutUs";
import Academy from "CATX/Academy";
import NFT from "CATX/NFT";
import Tokenomic from "CATX/Tokenomic";
import ContactUs from "CATX/ContactUs";
import Cube from "CATX/Cube";
import NewsEvent from "CATX/NewsEvent";
import Share2Earn from "CATX/Share2Earn";
import ProductBrand from "CATX/ProductBrand";
import Timeline2 from "CATX/Timeline2";

ReactDOM.render(
  <BrowserRouter>
    <Switch>
      <Switch>
        <Route path="/index" render={(props) => <Index {...props} />} />
        <Route path="/about" render={(props) => <AboutUs {...props} />} />
        <Route path="/academy" render={(props) => <Academy {...props} />} />
        <Route path="/NFT" render={(props) => <NFT {...props} />} />
        <Route path="/tokenomics" render={(props) => <Tokenomic {...props} />} />
        <Route path="/contact-us" render={(props) => <ContactUs {...props} />} />
        <Route path="/cube" render={(props) => <Cube {...props} />} />
        <Route path="/news-and-events" render={(props) => <NewsEvent {...props} />} />
        <Route path="/share2earn" render={(props) => <Share2Earn {...props} />} />
        <Route path="/product-and-brand" render={(props) => <ProductBrand {...props} />} />
        <Route path="/timeline2" render={(props) => <Timeline2 {...props} />} />

        <Redirect to="/index" />
        <Redirect from="/" to="/index" />
      </Switch>
    </Switch>
  </BrowserRouter>,
  document.getElementById("root")
);

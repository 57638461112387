import React from "react";
import { Container, Row, Col } from "reactstrap";
import "../assets/css/home.scss";
import { FaAngleRight } from 'react-icons/fa';
import team1_active from "../assets/img/home/team1_active.png";
import team2_active from "../assets/img/home/team2_active.png";
import team3_active from "../assets/img/home/team3_active.png";
import team4_active from "../assets/img/home/team4_active.png";
import team5_active from "../assets/img/home/team5_active.png";
import team6_active from "../assets/img/home/team6_active.png";
import team7_active from "../assets/img/home/team7_active.png";
import team8_active from "../assets/img/home/team8_active.png";

import team1_inactive from "../assets/img/home/team1_inactive.png";
import team2_inactive from "../assets/img/home/team2_inactive.png";
import team3_inactive from "../assets/img/home/team3_inactive.png";
import team4_inactive from "../assets/img/home/team4_inactive.png";
import team5_inactive from "../assets/img/home/team5_inactive.png";
import team6_inactive from "../assets/img/home/team6_inactive.png";
import team7_inactive from "../assets/img/home/team7_inactive.png";
import team8_inactive from "../assets/img/home/team8_inactive.png";

import Fade from 'react-reveal/Fade';
import Slide from 'react-reveal/Slide';
import Flip from 'react-reveal/Flip';
import { Zoom } from "react-reveal";

function HomePage() {

    return (
        <>
            <div id="home">
                <div className="container-fluid headerBg py-5">
                    <Container className="py-5">
                        <Row className="justify-content-center align-items-startp pt-5">
                            <Col lg={7} md={8} sm={12} xs={12}>
                                <div className="text-center">
                                    <Fade top duration={3000}>
                                        <div className="pt-5">
                                            <span className="paragraph mb-4 text-white headerDesc">As market leaders, we believe in creating innovative
                                                investment opportunities for everyone through our ecosystem
                                                with a Purpose, Transparency, and Digital Ownership
                                            </span>
                                        </div>
                                    </Fade>


                                    <div className='d-flex pt-2 justify-content-center align-items-center'>
                                        <div className="pt-3 pr-3 headerButtonBox2 mb-1">
                                            <a className="hoverEffect-dropdown" href="/about">
                                                <img className="img-fluid headerArrow" src={require("../assets/img/home/learnmore_arrow.png").default} />
                                            </a>
                                        </div>
                                        <div className='text-center mt-2'>
                                            <a className="hoverEffect-dropdown" href="/about">
                                                <span className='homeHeader-buttonText'>
                                                    Learn More
                                                </span>
                                            </a>
                                        </div>
                                    </div>
                                </div>
                            </Col>
                            <Col lg={12} md={12} sm={12} xs={12}>
                                <div className="text-center">
                                    <Zoom duration={3000}>
                                        <div className="pt-5">
                                            <img className="img-fluid" src={require("../assets/img/home/header_bg.png").default} />
                                        </div>
                                    </Zoom>
                                </div>
                            </Col>
                        </Row>
                    </Container>
                </div>

                <div className="container-fluid visionBg2 py-5 position-relative">
                    <Container className="py-5">
                        <Row className="justify-content-center align-items-start py-5">
                            <Col lg={10} md={12} sm={12} xs={12}>
                                <div className="text-center">
                                    <span className="paragraph mb-4 text-black visionText">
                                        Our Vision
                                    </span>
                                    <br /><br />
                                    <Slide bottom duration={2000}>
                                        <div className="d-none d-lg-block">
                                            <span className="paragraph mb-4 text-black visionDescText">
                                                Building Futures,
                                            </span>
                                            <span className="paragraph mb-4 text-black visionDescText ml-2">
                                                Through
                                                <span className="paragraph mb-4 text-black visionDescText2 ml-2">
                                                    Blockchain
                                                </span>
                                                .
                                            </span>
                                        </div>
                                    </Slide>

                                    <div className="d-block d-lg-none">
                                        <span className="paragraph mb-4 text-black visionDescText">
                                            Building Futures,
                                        </span>
                                        <br />
                                        <span className="paragraph mb-4 text-black visionDescText ml-2">
                                            Through
                                            <span className="paragraph mb-4 text-black visionDescText2 ml-2">
                                                Blockchain
                                            </span>
                                            .
                                        </span>
                                    </div>

                                </div>
                            </Col>
                        </Row>
                    </Container>
                </div>

                <div className="container-fluid omoBg py-5">
                    <Container className="py-5">
                        <Row className="justify-content-center align-items-center text-center">

                            <Col lg={10} md={12} sm={12} xs={12}>
                                <div className="m-4 py-5">
                                    <Flip top duration={3000}>
                                        <img className="img-fluid" src={require("../assets/img/home/omo_title.png").default} />
                                    </Flip>
                                </div>
                                <div className="m-4 pt-5">
                                    <Zoom duration={3000}>
                                        <div className="position-relative">
                                            <img className="img-fluid" src={require("../assets/img/home/omo_logo2.png").default} />
                                            <a href="/share2earn"><div className="position-absolute omo-op1"></div></a>
                                            <a href="/tokenomics"><div className="position-absolute omo-op2"></div></a>
                                            <a href="/product-and-brand"><div className="position-absolute omo-op3"></div></a>
                                            <a href="/academy"><div className="position-absolute omo-op4"></div></a>
                                            <a href="/NFT"><div className="position-absolute omo-op5"></div></a>
                                        </div>
                                    </Zoom>
                                </div>
                            </Col>
                        </Row>
                    </Container>
                </div>

                <div className="container-fluid py-5">
                    <Container className="py-5 text-center">
                        <span className="paragraph mb-4 text-black visionText">
                            Core Team
                        </span>
                        <Row className="justify-content-between align-items-center pt-5 mt-2">
                            <Col lg={3} md={6} sm={12} xs={12} className="mb-5">
                                <Flip top duration={window.innerWidth > 300 ? 2000 : 0}>
                                    <Fade top duration={window.innerWidth < 300 ? 2000 : 0}>
                                        <div className="">
                                            <img
                                                src={team1_inactive}
                                                className="img-fluid quicklinks-img"
                                                onMouseOver={e => e.currentTarget.src = team1_active}
                                                onMouseLeave={e => e.currentTarget.src = team1_inactive}
                                            />
                                            <Row className="justify-content-left align-items-center pt-2">
                                                <Col lg={12} md={12} sm={12} xs={12} className="">
                                                    <div className="">
                                                        <span className="paragraph mb-4 text-black teamMemberName">
                                                            Cat C
                                                        </span>
                                                    </div>
                                                </Col>
                                                <Col lg={12} md={12} sm={12} xs={12}>
                                                    <span className="paragraph mb-4 text-black teamMemberPosition">
                                                        Founder of CATX
                                                    </span>
                                                </Col>
                                            </Row>
                                        </div>
                                    </Fade>
                                </Flip>
                            </Col>
                            <Col lg={3} md={6} sm={12} xs={12} className="mb-5">
                                <Flip top duration={window.innerWidth > 300 ? 2000 : 0}>
                                    <Fade top duration={window.innerWidth < 300 ? 2000 : 0}>
                                        <div className="">
                                            <img
                                                src={team2_inactive}
                                                className="img-fluid quicklinks-img"
                                                onMouseOver={e => e.currentTarget.src = team2_active}
                                                onMouseLeave={e => e.currentTarget.src = team2_inactive}
                                            />
                                            <Row className="justify-content-left align-items-center pt-2">
                                                <Col lg={12} md={12} sm={12} xs={12} className="">
                                                    <div className="">
                                                        <span className="paragraph mb-4 text-black teamMemberName">
                                                            Jason Yee
                                                        </span>
                                                    </div>
                                                </Col>
                                                <Col lg={12} md={12} sm={12} xs={12}>
                                                    <span className="paragraph mb-4 text-black teamMemberPosition">
                                                        Chief Executive Officer
                                                    </span>
                                                </Col>
                                            </Row>
                                        </div>
                                    </Fade>
                                </Flip>
                            </Col>
                            <Col lg={3} md={6} sm={12} xs={12} className="mb-5">
                                <Flip top duration={window.innerWidth > 300 ? 2000 : 0}>
                                    <Fade top duration={window.innerWidth < 300 ? 2000 : 0}>
                                        <div className="">
                                            <img
                                                src={team3_inactive}
                                                className="img-fluid quicklinks-img"
                                                onMouseOver={e => e.currentTarget.src = team3_active}
                                                onMouseLeave={e => e.currentTarget.src = team3_inactive}
                                            />
                                            <Row className="justify-content-left align-items-center pt-2">
                                                <Col lg={12} md={12} sm={12} xs={12} className="">
                                                    <div className="">
                                                        <span className="paragraph mb-4 text-black teamMemberName">
                                                            Dennis Yin
                                                        </span>
                                                    </div>
                                                </Col>
                                                <Col lg={12} md={12} sm={12} xs={12}>
                                                    <span className="paragraph mb-4 text-black teamMemberPosition">
                                                        Chief Brand Officer
                                                    </span>
                                                </Col>
                                            </Row>
                                        </div>
                                    </Fade>
                                </Flip>
                            </Col>
                            <Col lg={3} md={6} sm={12} xs={12} className="mb-5">
                                <Flip top duration={window.innerWidth > 300 ? 2000 : 0}>
                                    <Fade top duration={window.innerWidth < 300 ? 2000 : 0}>
                                        <div className="">
                                            <img
                                                src={team4_inactive}
                                                className="img-fluid quicklinks-img"
                                                onMouseOver={e => e.currentTarget.src = team4_active}
                                                onMouseLeave={e => e.currentTarget.src = team4_inactive}
                                            />
                                            <Row className="justify-content-left align-items-center pt-2">
                                                <Col lg={12} md={12} sm={12} xs={12} className="">
                                                    <div className="">
                                                        <span className="paragraph mb-4 text-black teamMemberName">
                                                            Jeff Chin
                                                        </span>
                                                    </div>
                                                </Col>
                                                <Col lg={12} md={12} sm={12} xs={12}>
                                                    <span className="paragraph mb-4 text-black teamMemberPosition">
                                                        Chief Marketing Officer
                                                    </span>
                                                </Col>
                                            </Row>
                                        </div>
                                    </Fade>
                                </Flip>
                            </Col>
                            <Col lg={3} md={6} sm={12} xs={12} className="mb-5">
                                <Flip bottom duration={window.innerWidth > 300 ? 2000 : 0}>
                                    <Fade bottom duration={window.innerWidth < 300 ? 2000 : 0}>
                                        <div className="">
                                            <img
                                                src={team5_inactive}
                                                className="img-fluid quicklinks-img"
                                                onMouseOver={e => e.currentTarget.src = team5_active}
                                                onMouseLeave={e => e.currentTarget.src = team5_inactive}
                                            />

                                            <Row className="justify-content-left align-items-center pt-2">
                                                <Col lg={12} md={12} sm={12} xs={12} className="">
                                                    <div className="">
                                                        <span className="paragraph mb-4 text-black teamMemberName">
                                                            Jason Wong
                                                        </span>
                                                    </div>
                                                </Col>
                                                <Col lg={12} md={12} sm={12} xs={12}>
                                                    <span className="paragraph mb-4 text-black teamMemberPosition">
                                                        Tech Advisor
                                                    </span>
                                                </Col>
                                            </Row>
                                        </div>
                                    </Fade>
                                </Flip>
                            </Col>
                            <Col lg={3} md={6} sm={12} xs={12} className="mb-5">
                                <Flip bottom duration={window.innerWidth > 300 ? 2000 : 0}>
                                    <Fade bottom duration={window.innerWidth < 300 ? 2000 : 0}>
                                        <div className="">
                                            <img
                                                src={team6_inactive}
                                                className="img-fluid quicklinks-img"
                                                onMouseOver={e => e.currentTarget.src = team6_active}
                                                onMouseLeave={e => e.currentTarget.src = team6_inactive}
                                            />

                                            <Row className="justify-content-left align-items-center pt-2">
                                                <Col lg={12} md={12} sm={12} xs={12} className="">
                                                    <div className="">
                                                        <span className="paragraph mb-4 text-black teamMemberName">
                                                            Steve Tan
                                                        </span>
                                                    </div>
                                                </Col>
                                                <Col lg={12} md={12} sm={12} xs={12}>
                                                    <span className="paragraph mb-4 text-black teamMemberPosition">
                                                        CATX Project Advisor
                                                    </span>
                                                </Col>
                                            </Row>
                                        </div>
                                    </Fade>
                                </Flip>
                            </Col>
                            <Col lg={3} md={6} sm={12} xs={12} className="mb-5">
                                <Flip bottom duration={window.innerWidth > 300 ? 2000 : 0}>
                                    <Fade bottom duration={window.innerWidth < 300 ? 2000 : 0}>
                                        <div className="">
                                            <img
                                                src={team7_inactive}
                                                className="img-fluid quicklinks-img"
                                                onMouseOver={e => e.currentTarget.src = team7_active}
                                                onMouseLeave={e => e.currentTarget.src = team7_inactive}
                                            />
                                            <Row className="justify-content-left align-items-center pt-2">
                                                <Col lg={12} md={12} sm={12} xs={12} className="">
                                                    <div className="">
                                                        <span className="paragraph mb-4 text-black teamMemberName">
                                                            Norman Pang
                                                        </span>
                                                    </div>
                                                </Col>
                                                <Col lg={12} md={12} sm={12} xs={12}>
                                                    <span className="paragraph mb-4 text-black teamMemberPosition">
                                                        CATX Project Advisor
                                                    </span>
                                                </Col>
                                            </Row>
                                        </div>
                                    </Fade>
                                </Flip>
                            </Col>
                            <Col lg={3} md={6} sm={12} xs={12} className="mb-5">
                                <Flip bottom duration={window.innerWidth > 300 ? 2000 : 0}>
                                    <Fade bottom duration={window.innerWidth < 300 ? 2000 : 0}>
                                        <div className="">
                                            <img
                                                src={team8_inactive}
                                                className="img-fluid quicklinks-img"
                                                onMouseOver={e => e.currentTarget.src = team8_active}
                                                onMouseLeave={e => e.currentTarget.src = team8_inactive}
                                            />
                                            <Row className="justify-content-left align-items-center pt-2">
                                                <Col lg={12} md={12} sm={12} xs={12} className="">
                                                    <div className="">
                                                        <span className="paragraph mb-4 text-black teamMemberName">
                                                            Sam Chew
                                                        </span>
                                                    </div>
                                                </Col>
                                                <Col lg={12} md={12} sm={12} xs={12}>
                                                    <span className="paragraph mb-4 text-black teamMemberPosition">
                                                        Chief Academy Officer
                                                    </span>
                                                </Col>
                                            </Row>
                                        </div>
                                    </Fade>
                                </Flip>
                            </Col>
                        </Row>
                    </Container>
                </div>

                <div className="container-fluid affiliationBg py-5">
                    <Container className="py-5 text-center">
                        <span className="paragraph mb-4 visionText affTitle">
                            Affiliations
                        </span>
                        <Row className="justify-content-center align-items-center pt-5 pb-5 text-center pl-0 pr-0">
                            <Col lg={3} md={6} sm={12} xs={12} className=" pl-0 pr-0">
                                <div className="m-4">
                                    <Zoom duration={2000}>
                                        <div className="text-center">
                                            <img className="img-fluid" src={require("../assets/img/home/aff_logo1.png").default} />
                                        </div>
                                    </Zoom>
                                </div>
                            </Col>
                            <Col lg={3} md={6} sm={12} xs={12}>
                                <div className="m-4">
                                    <Zoom duration={2000}>
                                        <div className="text-center">
                                            <img className="img-fluid" src={require("../assets/img/home/aff_logo2.png").default} />
                                        </div>
                                    </Zoom>
                                </div>
                            </Col>
                            <Col lg={3} md={6} sm={12} xs={12}>
                                <div className="m-4">
                                    <Zoom duration={2000}>
                                        <div className="text-center">
                                            <img className="img-fluid" src={require("../assets/img/home/aff_logo3.png").default} />
                                        </div>
                                    </Zoom>
                                </div>
                            </Col>
                            <Col lg={3} md={6} sm={12} xs={12}>
                                <div className="m-4">
                                    <Zoom duration={2000}>
                                        <div className="text-center">
                                            <img className="img-fluid" src={require("../assets/img/home/aff_logo4.png").default} />
                                        </div>
                                    </Zoom>
                                </div>
                            </Col>
                        </Row>
                    </Container>
                </div>
            </div>
        </>
    );
}

export default HomePage;

import React, { useRef } from "react";
import { Container, Row, Col } from "reactstrap";
import "../assets/css/productbrand.scss";
import { FaAngleRight, FaInstagram, FaAngleLeft } from 'react-icons/fa';
import DarkFooter from "components/Footers/DarkFooter";
import IndexNavbar from "components/Navbars/IndexNavbar";
import { Carousel } from 'react-responsive-carousel';
import "react-responsive-carousel/lib/styles/carousel.min.css";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Box from '@mui/material/Box';
import Slider2 from '@mui/material/Slider';
import Next from "../assets/img/productbrand/next.png";
import Prev from "../assets/img/productbrand/prev.png";
import Fade from 'react-reveal/Fade';
import Slide from 'react-reveal/Slide';
import Flip from 'react-reveal/Flip';
import { Zoom } from "react-reveal";

import event1Img from "../assets/img/productbrand/events/event1.png";
import event2Img from "../assets/img/productbrand/events/event2.png";
import event3Img from "../assets/img/productbrand/events/event3.png";
import event4Img from "../assets/img/productbrand/events/event4.png";
import event5Img from "../assets/img/productbrand/events/event5.png";
import event6Img from "../assets/img/productbrand/events/event6.png";
import event7Img from "../assets/img/productbrand/events/event7.png";
import event8Img from "../assets/img/productbrand/events/event8.png";
import event9Img from "../assets/img/productbrand/events/event9.png";
import event10Img from "../assets/img/productbrand/events/event10.png";
import event11Img from "../assets/img/productbrand/events/event11.png";
import event12Img from "../assets/img/productbrand/events/event12.png";
import event13Img from "../assets/img/productbrand/events/event13.png";
import event14Img from "../assets/img/productbrand/events/event14.png";
import event15Img from "../assets/img/productbrand/events/event15.png";
import event16Img from "../assets/img/productbrand/events/event16.png";
import event17Img from "../assets/img/productbrand/events/event17.png";
import event18Img from "../assets/img/productbrand/events/event18.png";
import event19Img from "../assets/img/productbrand/events/event19.png";
import event20Img from "../assets/img/productbrand/events/event20.png";
import event21Img from "../assets/img/productbrand/events/event21.png";
import event22Img from "../assets/img/productbrand/events/event22.png";
import event23Img from "../assets/img/productbrand/events/event23.png";
import event24Img from "../assets/img/productbrand/events/event24.png";
import event25Img from "../assets/img/productbrand/events/event25.png";

const EventArray =
    [
        { image: event1Img, date: "JUNE 2018", desc: "Grand Launch of BARDOX 1.0" },
        { image: event2Img, date: "NOV 2018", desc: "BARDOX First Curved LED Billboard Advertisement at Jalan Imbi, KL" },
        { image: event3Img, date: "FEB 2019", desc: "BARDOX available at Sasa Nationwide" },
        { image: event4Img, date: "MARCH 2019", desc: "BARDOX available on Astro Go Shop and CJ Wow Shop" },
        { image: event5Img, date: "JUNE 2019", desc: "BARDOX Exhibition at Hong Kong Sister Beauty Expo" },
        { image: event6Img, date: "JULY 2019", desc: "BARDOX recommended by well-know Hong Kong Artistes" },
        { image: event7Img, date: "AUG 2019", desc: "BARDOX Roadshow at Sunway Pyramind" },
        { image: event8Img, date: "AUG 2019", desc: "BARDOX 2nd Product Launching - BOOMBA" },
        { image: event9Img, date: "SEP 2019", desc: "Malaysia’s first online brand to advertise on Bukit Bintang’s largest billboard at Pavilion Kuala Lumpur" },
        { image: event10Img, date: "APR 2020", desc: "BARDOX CSR collaboration with Powerplant" },
        { image: event11Img, date: "MAY 2020", desc: "Launch of All New BARDOX 2.0 & Limited Edition Boomba" },
        { image: event12Img, date: "JUNE 2020", desc: "BARDOX 2.0 Billboard Advertisement along Federal Highway and Mid Valley Megamall" },
        { image: event13Img, date: "AUG 2020", desc: "BARDOX LED Billboard Advertising along LDP, Federal Highway, and Jalan Ampang" },
        { image: event14Img, date: "NOV 2020", desc: "Launch of BARDOX 3rd Product - ROLLIEPOP" },
        { image: event15Img, date: "MARCH 2021", desc: "Launch of New Brand - Daily" },
        { image: event16Img, date: "APRIL 2021", desc: "Launch of DAILY 1st product - fine cleansing oil" },
        { image: event17Img, date: "JUNE 2021", desc: "BARDOX 3rd Birthday launch of new flavour, salted egg" },
        { image: event18Img, date: "JUNE 2021", desc: "BARDOX Greatest Collaboration Ever in history – BARDOX x NERDUNIT x B@ERBRICK, limited to 1000 sets only" },
        { image: event19Img, date: "JULY 2021", desc: "Launch of DAILY 2nd product - mona & luna mask" },
        { image: event20Img, date: "DEC 2021", desc: "Launch of new upgrade version for DAILY fine cleansing oil" },
        { image: event21Img, date: "DEC 2021", desc: "Launch of new brand - ANTIPLUS, and 1st product - Antiplus Anti-Cream Skin Lotion" },
        { image: event22Img, date: "JUNE 2022", desc: "Rebranding of BARDOX" },
        { image: event23Img, date: "JUNE 2022", desc: "Launch of BARDOX new products - Mushroom Meal Replacement and Gac Fruit Fiber" },
        { image: event24Img, date: "AUG 2022", desc: "Launch of DAILY 3rd Product - Sun-day Sunscreen Cushion" },
        { image: event25Img, date: "SEP 2022", desc: "Launch of BOOMBA Plus" },
        {},
        {}
    ]

const bordoxImg = [
    {
        img: require("../assets/img/productbrand/bordox_intro1.png").default,
    },
    {
        img: require("../assets/img/productbrand/bordox_intro2.png").default,
    },
    {
        img: require("../assets/img/productbrand/bordox_intro3.png").default,
    },
];

const ohmydailyImg = [
    {
        img: require("../assets/img/productbrand/ohmydaily_intro1.png").default,
    },
    {
        img: require("../assets/img/productbrand/ohmydaily_intro2.png").default,
    },
    {
        img: require("../assets/img/productbrand/ohmydaily_intro3.png").default,
    },
    {
        img: require("../assets/img/productbrand/ohmydaily_intro4.png").default,
    },
    {
        img: require("../assets/img/productbrand/ohmydaily_intro5.png").default,
    },
];

const antiplusImg = [
    {
        img: require("../assets/img/productbrand/antiplus_intro1.png").default,
    },
    {
        img: require("../assets/img/productbrand/antiplus_intro2.png").default,
    },
];

const marks = [
    {
        step: 0,
        value: 24,
        label: '',
    },
    {
        step: 1,
        value: 30,
        label: '',
    },
    {
        step: 2,
        value: 36,
        label: '',
    },
    {
        step: 3,
        value: 40,
        label: '',
    },
    {
        step: 4,
        value: 42,
        label: '',
    },
    {
        step: 5,
        value: 44,
        label: '',
    },
    {
        step: 6,
        value: 45,
        label: '',
    },
    {
        step: 7,
        value: 47,
        label: '',
    },
    {
        step: 8,
        value: 48,
        label: '',
    },
    {
        step: 9,
        value: 55,
        label: '',
    },
    {
        step: 10,
        value: 56,
        label: '',
    },
    {
        step: 11,
        value: 58,
        label: '',
    },
    {
        step: 12,
        value: 61,
        label: '',
    },
    {
        step: 13,
        value: 63,
        label: '',
    },
    {
        step: 14,
        value: 74,
        label: '',
    },
    {
        step: 15,
        value: 75,
        label: '',
    },
    {
        step: 16,
        value: 77,
        label: '',
    },
    {
        step: 17,
        value: 78,
        label: '',
    },
    {
        step: 18,
        value: 80,
        label: '',
    },
    {
        step: 19,
        value: 82,
        label: '',
    },
    {
        step: 20,
        value: 83,
        label: '',
    },
    {
        step: 21,
        value: 93,
        label: '',
    },
    {
        step: 22,
        value: 95,
        label: '',
    },
    {
        step: 23,
        value: 97,
        label: '',
    },
    {
        step: 24,
        value: 98,
        label: '',
    },
];

function ProductBrand() {
    const [imageIndex, setImageIndex] = React.useState(0);
    const [value2, setValue2] = React.useState(98);
    const [projectIndex, setProjectIndex] = React.useState(0);

    var labelIndex = 0;

    React.useEffect(() => {
        document.body.classList.add("index-page");
        document.body.classList.add("sidebar-collapse");
        document.documentElement.classList.remove("nav-open");
        window.scrollTo(0, 0);
        document.body.scrollTop = 0;
        return function cleanup() {
            document.body.classList.remove("index-page");
            document.body.classList.remove("sidebar-collapse");
        };
    }, []);

    React.useEffect(() => {
        slider.current.slickGoTo(23);
    }, [])


    function valuetext(value) {
        return `${value}`;
    }

    function valueLabelFormat(value) {
        // setLabelIndex(marks.findIndex((mark) => mark.value === value) + 1);
        var marks2 = marks.findIndex((mark) => mark.value === value);
        // setLabelIndex(marks2);
        labelIndex = marks2;
        return marks2;
    }

    function getMarkValue(value) {
        return marks[value].value;
    }

    const slider = useRef();
    const slider2 = useRef();

    const handleChange = (e) => {
        // setValue2(labelIndex - 1);
        // console.log("AAA", e.target.value);
        // if(e.target.value > 94)
        // {
        //     return;
        // }
        setValue2(e.target.value)
        var slideNum = marks.findIndex(i => i.value === e.target.value);
        setTimeout(() => {
            slider.current.slickGoTo(slideNum);
        }, 100);
    }

    const NextArrow = ({ onClick }) => {
        return (
            <div className="arrow next timelineNext" onClick={onClick} style={{ display: value2 === 98 ? "none" : "block" }}>
                <img className="nextImg" src={Next} />
            </div>
        );
    };

    const PrevArrow = ({ onClick }) => {
        return (
            <div className="arrow prev timelinePrev" onClick={onClick} style={{ display: value2 === 24 ? "none" : "block" }}>
                <img className="prevImg" src={Prev} />
            </div>
        );
    };

    const settings = {
        dots: false,
        infinite: false,
        lazyLoad: true,
        autoplay: false,
        swipeable: false,
        speed: 100,
        slidesToShow: window.innerWidth > 960 ? 3 : 1,
        centerMode: true,
        centerPadding: 0,
        nextArrow: <NextArrow />,
        prevArrow: <PrevArrow />,
        beforeChange: (current, next) => { setImageIndex2(next) },
        // beforeChange: (current, next) => { setImageIndex(next) },
    };

    function setImageIndex2(value) {
        setImageIndex(value);

        var marks2 = marks.find((mark) => mark.step === value);
        // console.log("MARK", marks2)
        if (marks2 === undefined) {
            return;
        }
        else {
            setValue2(marks2.value);
        }
    }


    return (
        <>
            <IndexNavbar />
            <div id="productbrand" className="pt-5">
                <div className="container-fluid py-5 position-relative">
                    <Container className="py-5 text-center my-5">
                        <Fade top duration={3000}>
                            <div>
                                <span className="paragraph mb-4 text-black visionText">
                                    PRODUCT AND BRAND
                                </span><br /><br />
                                <img className="img-fluid headerArrow" src={require("../assets/img/academy/arrow-down.png").default} /> <br />
                            </div>
                        </Fade>

                        <Row className="justify-content-center align-items-center py-3" id="carousel1">
                            <Row className="justify-content-center align-items-center">
                                <Col lg={10} md={10} sm={12} xs={12} className="text-center">
                                    <div className="mt-4">
                                        <span className="headerDetails">
                                            With more than 5 years of experience in Web 1.0 and 2.0, we have build our brands; BARDOX, OHMYDAILY and ANTIPLUS to become our market leading products with a community of more than 50,000 distributors and customers.                                    </span>
                                    </div>
                                </Col>
                            </Row>
                            <Col lg={12} md={12} sm={12} xs={12} className="text-center pt-5">
                                <Zoom duration={3000}>
                                    <Carousel preventMovementUntilSwipeScrollTolerance={true} swipeable={true} showThumbs={false} autoPlay={true} infiniteLoop={true}>
                                        <div className="mb-5">
                                            <img src={require("../assets/img/productbrand/header_img.png").default} className="img-fluid" />
                                        </div>
                                        <div className="mb-5">
                                            <img src={require("../assets/img/productbrand/header_img2.png").default} className="img-fluid" />
                                        </div>
                                        <div className="mb-5">
                                            <img src={require("../assets/img/productbrand/header_img3.png").default} className="img-fluid" />
                                        </div>
                                    </Carousel>
                                </Zoom>
                            </Col>
                        </Row>
                    </Container>
                    {/* <img src={require("../assets/img/productbrand/header_bg.png").default} className="img-fluid headerDetailsBg" /> */}
                </div>

                <div className="container-fluid grayBg py-5 pl-0 pr-0">
                    <div className="container-fluid pb-lg-0 pl-0 pr-0">
                        <Container className="text-center" id="carousel2">
                            <Row className="justify-content-center align-items-center">
                                <Col lg={12} md={12} sm={12} xs={12} className="text-center">
                                    <Carousel preventMovementUntilSwipeScrollTolerance={true} swipeable={true} showThumbs={false} autoPlay={true} infiniteLoop={true}>
                                        <div className="m-4 m-lg-5 m-md-5">
                                            <Row className="justify-content-center align-items-center">
                                                <Col lg={3} md={6} sm={6} xs={6} className="text-center">
                                                    <div className="mb-4">
                                                        <img src={require("../assets/img/productbrand/slider1.png").default} className="img-fluid" />
                                                    </div>
                                                </Col>
                                                <Col lg={3} md={6} sm={6} xs={6} className="text-center">
                                                    <div className="mb-4">
                                                        <img src={require("../assets/img/productbrand/slider2.png").default} className="img-fluid" />
                                                    </div>
                                                </Col>
                                                <Col lg={3} md={6} sm={6} xs={6} className="text-center">
                                                    <div className="mb-4">
                                                        <img src={require("../assets/img/productbrand/slider3.png").default} className="img-fluid" />
                                                    </div>
                                                </Col>
                                                <Col lg={3} md={6} sm={6} xs={6} className="text-center">
                                                    <div className="mb-4">
                                                        <img src={require("../assets/img/productbrand/slider4.png").default} className="img-fluid" />
                                                    </div>
                                                </Col>
                                            </Row>
                                        </div>
                                        <div className="m-4 m-lg-5 m-md-5">
                                            <Row className="justify-content-center align-items-center">
                                                <Col lg={3} md={6} sm={6} xs={6} className="text-center">
                                                    <div className="mb-4">
                                                        <img src={require("../assets/img/productbrand/slider1.png").default} className="img-fluid" />
                                                    </div>
                                                </Col>
                                                <Col lg={3} md={6} sm={6} xs={6} className="text-center">
                                                    <div className="mb-4">
                                                        <img src={require("../assets/img/productbrand/slider2.png").default} className="img-fluid" />
                                                    </div>
                                                </Col>
                                                <Col lg={3} md={6} sm={6} xs={6} className="text-center">
                                                    <div className="mb-4">
                                                        <img src={require("../assets/img/productbrand/slider3.png").default} className="img-fluid" />
                                                    </div>
                                                </Col>
                                                <Col lg={3} md={6} sm={6} xs={6} className="text-center">
                                                    <div className="mb-4">
                                                        <img src={require("../assets/img/productbrand/slider4.png").default} className="img-fluid" />
                                                    </div>
                                                </Col>
                                            </Row>
                                        </div>
                                    </Carousel>
                                </Col>
                            </Row>
                        </Container>
                    </div>
                </div>

                <div className="container-fluid py-lg-5 py-sm-3">
                    <div className="container-fluid pt-5 pb-lg-0">
                        <Container className="text-center">
                            <Row className="justify-content-center align-items-center">
                                <Col lg={12} md={12} sm={12} xs={12} className="text-left">
                                    <Zoom duration={3000}>
                                        <Row className="justify-content-center align-items-center">
                                            <Col lg={8} md={10} sm={12} xs={12} className="text-center">
                                                <div className="">
                                                    <div className="py-2">
                                                        <span className="bordoxLogo_text">HEALTHCARE SERIES</span>
                                                    </div>
                                                    <img src={require("../assets/img/productbrand/bordox_logo.png").default} className="img-fluid" />
                                                    <div className="pt-4">
                                                        <span className="bordox_introText">BARDOX was founded in 2018. Develop to help address some of the every- day health challenges associated with modern life. We are committed to providing innovative products that make taking your daily health essential simple and delicious. BARDOX always adhered to the highest professional standards. Using expert technology and natural ingredients to develop a variety of health products, and is committed to help the society to re-discover their health and beauty in a simple and convenient way.</span> <br /><br />
                                                        <div className="d-flex justify-content-center align-items-center">
                                                            <img src={require("../assets/img/productbrand/insta-icon.png").default} className="img-fluid" />
                                                            <div className="mt-1 ml-2">
                                                                <a className="hoverEffect-dropdown" href="https://www.instagram.com/bardox.co/" target="_blank">
                                                                    <span className="instaText">bardox.co</span>
                                                                </a>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </Col>
                                        </Row>
                                    </Zoom>
                                </Col>
                            </Row>
                        </Container>
                    </div>
                </div>

                <div className="position-relative scrollHorizontalContainer">
                    <div className="container-fluid">
                        <Row className="justify-content-center align-items-center">
                            <Col lg={12} md={12} sm={12} xs={12} className="text-center">
                                <div id="" className="" onMouseLeave={() => setProjectIndex(0)}>
                                    <div className="horizontal-scroll-wrapper squares" id="projectContainer">
                                        {bordoxImg.map((item, i) => (
                                            <div key={i} className="project-slide-container">
                                                <div
                                                    className={i === projectIndex ? "project-slide-default" : "project-slide"}
                                                    style={{ backgroundImage: "url(" + item.img + ")" }}
                                                    onMouseOver={() => setProjectIndex(10)}
                                                >
                                                    <div className="project-img-overlay">
                                                        <div className="project-slide-title-container">
                                                            <div className="text-left">
                                                                <span className="project-slide-title">
                                                                    {item.title}
                                                                </span>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        ))}
                                    </div>
                                </div>
                            </Col>
                        </Row>
                    </div>
                </div>

                <div className="container-fluid pt-lg-5 pt-md-5 pt-sm-3">
                    <div className="container-fluid pt-5">
                        <Container className="text-center">
                            <Row className="justify-content-center align-items-center">
                                <Col lg={12} md={12} sm={12} xs={12} className="text-left">
                                    <Zoom duration={3000}>
                                        <Row className="justify-content-center align-items-center">
                                            <Col lg={8} md={10} sm={12} xs={12} className="text-center">
                                                <div className="">
                                                    <div className="py-2">
                                                        <span className="bordoxLogo_text">SKINCARE SERIES</span>
                                                    </div>
                                                    <img src={require("../assets/img/productbrand/ohmydaily.png").default} className="img-fluid" />
                                                    <div className="pt-4">
                                                        <span className="bordox_introText">DAILY, a brand that is necessary, irreplaceable, and tailored for every individual. From your DAILY skin care routines, DAILY thoughts, and every detail that touches you, DAILY will always be by your side and take part in every aspect of your life.</span> <br /><br />
                                                        <div className="d-flex justify-content-center align-items-center">
                                                            <img src={require("../assets/img/productbrand/insta-icon.png").default} className="img-fluid" />
                                                            <div className="mt-1 ml-2">
                                                                <a className="hoverEffect-dropdown" href="https://www.instagram.com/ohmydaily.co/" target="_blank">
                                                                    <span className="instaText">ohmydaily.co</span>
                                                                </a>                                                         
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </Col>
                                        </Row>
                                    </Zoom>
                                </Col>
                            </Row>
                        </Container>
                    </div>
                </div>

                <div className="position-relative scrollHorizontalContainer">
                    <div className="container-fluid">
                        <Row className="justify-content-center align-items-center">
                            <Col lg={12} md={12} sm={12} xs={12} className="text-center">
                                <div id="" className="" onMouseLeave={() => setProjectIndex(0)}>
                                    <div className="horizontal-scroll-wrapper squares" id="projectContainer">
                                        {ohmydailyImg.map((item, i) => (
                                            <div key={i} className="project-slide-container">
                                                <div
                                                    className={i === projectIndex ? "project-slide-default" : "project-slide"}
                                                    style={{ backgroundImage: "url(" + item.img + ")" }}
                                                    onMouseOver={() => setProjectIndex(10)}
                                                >
                                                    <div className="project-img-overlay">
                                                        <div className="project-slide-title-container">
                                                            <div className="text-left">
                                                                <span className="project-slide-title">
                                                                    {item.title}
                                                                </span>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        ))}
                                    </div>
                                </div>
                            </Col>
                        </Row>
                    </div>
                </div>

                <div className="container-fluid py-lg-5 py-md-5 py-sm-3">
                    <div className="container-fluid pt-5 pb-5 pb-lg-0">
                        <Container className="text-center">
                            <Row className="justify-content-center align-items-center">
                                <Col lg={12} md={12} sm={12} xs={12} className="text-left">
                                    <Zoom duration={3000}>
                                        <Row className="justify-content-center align-items-center">
                                            <Col lg={8} md={10} sm={12} xs={12} className="text-center">
                                                <div className="">
                                                    <div className="py-2">
                                                        <span className="bordoxLogo_text">PROTECTION SERIES</span>
                                                    </div>
                                                    <img src={require("../assets/img/productbrand/antiplus.png").default} className="img-fluid" />
                                                    <div className="pt-4">
                                                        <span className="bordox_introText">Brings you a new form of protection aesthetically. Our cutting-edge technology, in-style and compactly-designed products intend to keep you well protected beyond the pandemic, becoming an intrinsic part of your way of life so that you may feel the utmost protection during your daily activities.</span> <br /><br />
                                                        <div className="d-flex justify-content-center align-items-center">
                                                            <img src={require("../assets/img/productbrand/insta-icon.png").default} className="img-fluid" />
                                                            <div className="mt-1 ml-2">
                                                                <a className="hoverEffect-dropdown" href="https://www.instagram.com/antiplus.co/" target="_blank">
                                                                    <span className="instaText">antiplus.co</span>
                                                                </a>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </Col>
                                        </Row>
                                    </Zoom>
                                </Col>
                            </Row>
                        </Container>
                    </div>

                    <div className="position-relative scrollHorizontalContainer pb-5 mb-5">
                        <div className="container-fluid">
                            <Row className="justify-content-center align-items-center ml-0 mr-0">
                                <Col lg={10} md={12} sm={12} xs={12} className="text-center">
                                    <div id="" className="" onMouseLeave={() => setProjectIndex(0)}>
                                        <div className="horizontal-scroll-wrapper squares" id="projectContainer">
                                            {antiplusImg.map((item, i) => (
                                                <div key={i} className="project-slide-container">
                                                    <div
                                                        className={i === projectIndex ? "project-slide-default" : "project-slide"}
                                                        style={{ backgroundImage: "url(" + item.img + ")" }}
                                                        onMouseOver={() => setProjectIndex(10)}
                                                    >
                                                        <div className="project-img-overlay">
                                                            <div className="project-slide-title-container">
                                                                <div className="text-left">
                                                                    <span className="project-slide-title">
                                                                        {item.title}
                                                                    </span>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            ))}
                                        </div>
                                    </div>
                                </Col>
                            </Row>
                        </div>
                    </div>

                </div>

                <div className="container-fluid grayBg py-5 pl-0 pr-0">
                    <div className="container-fluid pb-5 pb-lg-0 pl-0 pr-0">
                        <Container className="text-center py-5" id="carousel2">
                            <Row className="justify-content-center align-items-top">
                                <Col lg={12} md={12} sm={12} xs={12} className="text-center">
                                    <div className="position-relative">
                                        <Slider {...settings} ref={slider}>
                                            {
                                                EventArray.map((item, i) => (
                                                    <div key={i} className={imageIndex === i ? "slide2 activeSlide" : "slide2"}>
                                                        <div className="position-relative text-center">
                                                            <div className="text-center">
                                                                <img src={item.image} className="img-fluid mx-auto" />
                                                                <div className="pt-2">
                                                                    <span className="eventDateText">{item.date}</span> <br />
                                                                    <span className="eventDescText">{item.desc}</span> <br />
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                ))
                                            }
                                        </Slider>

                                        {/* <div className="position-absolute w-100 h-100 sliderBlockDiv"></div> */}
                                    </div>


                                    <div className="pt-5 d-none d-lg-block">
                                        <div className="position-relative">
                                            <img src={require("../assets/img/productbrand/timeline_logo1a.png").default} className="img-fluid" style={{ position: "absolute", top: "-11px", left: "20%", width: "8%" }} />
                                            <img src={require("../assets/img/productbrand/timeline_logo2a.png").default} className="img-fluid" style={{ position: "absolute", top: "5px", left: "70%", width: "8%" }} />
                                            <img src={require("../assets/img/productbrand/timeline_logo3a.png").default} className="img-fluid" style={{ position: "absolute", top: "-12px", left: "80%", width: "8%" }} />
                                            <img src={require("../assets/img/productbrand/timeline_logo4a.png").default} className="img-fluid" style={{ position: "absolute", top: "-5px", left: "89%", width: "8%" }} />
                                        </div>
                                    </div>

                                    <Box sx={{ width: "100%" }} className="pt-1 pt-lg-4 pt-md-4">
                                        <Slider2 ref={slider2}
                                            aria-label="Restricted values"
                                            //defaultValue={24}
                                            valueLabelFormat={valueLabelFormat}
                                            getAriaValueText={valuetext}
                                            step={null}
                                            valueLabelDisplay="auto"
                                            marks={marks}
                                            value={value2}
                                            onChange={(e) => handleChange(e)}
                                        />
                                    </Box>

                                    <Row className="justify-content-center align-items-top">
                                        <Col lg={2} md={2} sm={2} xs={2} className="text-center">
                                            <span className="text-white timelineYearText">2018</span>
                                        </Col>
                                        <Col lg={2} md={2} sm={2} xs={2} className="text-center">
                                            <span className="text-white timelineYearText">2019</span>
                                        </Col>
                                        <Col lg={2} md={2} sm={2} xs={2} className="text-center">
                                            <span className="text-white timelineYearText">2020</span>
                                        </Col>
                                        <Col lg={2} md={2} sm={2} xs={2} className="text-center">
                                            <span className="text-white timelineYearText">2021</span>
                                        </Col>
                                        <Col lg={2} md={2} sm={2} xs={2} className="text-center">
                                            <span className="text-white timelineYearText">2022</span>
                                        </Col>
                                    </Row>
                                </Col>
                            </Row>
                        </Container>
                    </div>
                </div>


            </div>
            <DarkFooter />
        </>
    );
}

export default ProductBrand;

import React, { useState } from "react";
import { Row, Col } from "reactstrap";
import Fade from 'react-reveal/Fade';
import Slide from 'react-reveal/Slide';
import Flip from 'react-reveal/Flip';
import "../assets/css/cube.scss";
import { FaCube } from "react-icons/fa";
import IndexNavbar from "components/Navbars/IndexNavbar";
import DarkFooter from "components/Footers/DarkFooter";

function Cube() {
    const [tokenSelect, setTokenSelect] = useState("cat");

    React.useEffect(() => {
        document.body.classList.add("index-page");
        document.body.classList.add("sidebar-collapse");
        document.documentElement.classList.remove("nav-open");
        window.scrollTo(0, 0);
        document.body.scrollTop = 0;
        return function cleanup() {
            document.body.classList.remove("index-page");
            document.body.classList.remove("sidebar-collapse");
        };
    }, []);

    return (
        <>
            <IndexNavbar />
            <div className="">
                <div className="container-fluid pt-5 px-0 mt-5" id="users">
                    <div className="container py-5 mt-5">
                        <div className="text-center">
                            <img src={require('../assets/img/users/header_icon.png').default} className="img-fluid" />
                            <Flip top>
                                <div className="row align-items-center justify-content-center mt-3">
                                    <div className="col-md-1 col-2 text-right">
                                        {/* <img src={require('../assets/img/smallcube.png').default} className="img-fluid" /> */}
                                    </div>
                                    <div className="col-md-8 col-8 text-center">
                                        <span className="main-title text-uppercase">What is Cube Social</span>
                                    </div>
                                    <div className="col-md-1 col-2 text-left">
                                        {/* <img src={require('../assets/img/smallcube.png').default} className="img-fluid" /> */}
                                    </div>
                                </div>
                            </Flip>
                            <Fade bottom>
                                <div className="row align-items-center justify-content-center mt-3">
                                    <div className="col-md-10 col-12 text-center">
                                        <span className="main-description">CUBE is reimagining the live streaming platform for crypto enthusiasts by building a decentralized ecosystem that makes it easy for trading, minting, global exposure, NFT live streaming, NFT video streaming, marketplaces, games, launchpads, and rewards. Now digital creators can publish their music and videos on the live streaming platform by tokenizing them as NFTs.</span>
                                    </div>
                                </div>
                            </Fade>
                            <div className="floatObj1">
                                <img src={require('../assets/img/users/floatObject1.png').default} className="img-fluid floating2" />
                            </div>
                        </div>
                    </div>
                    <div className="container py-5" id="socialfi">
                        <Flip top duration={2000}>
                            <div className="row align-items-center justify-content-center mt-3">
                                <div className="col-md-1 col-2 text-right">
                                    {/* <img src={require('../assets/img/smallcube.png').default} className="img-fluid" /> */}
                                </div>
                                <div className="col-md-3 col-6 text-center">
                                    <span className="title text-uppercase">SOCIALFI</span>
                                </div>
                                <div className="col-md-1 col-2 text-left">
                                    {/* <img src={require('../assets/img/smallcube.png').default} className="img-fluid" /> */}
                                </div>
                            </div>
                        </Flip>
                        <div id="social">
                            <div className="row mt-4">
                                <div className="col-md-3 content-left-container order-2 order-lg-1 order-md-1">
                                    <Fade left cascade duration={3000}>
                                        <div className="content-left mb-3 text-center text-md-left text-md-left">
                                            <span className="desc">SocialFi is a combination of social media and decentralized finance.(DeFi).</span>
                                        </div>
                                    </Fade>
                                </div>
                                <div className="col-md-6 order-1 order-lg-2 order-md-2">
                                    <Flip left duration={2000}>
                                        <img src={require('../assets/img/users/phone.png').default} className="img-fluid" />
                                    </Flip>
                                </div>
                                <div className="col-md-3 content-right-container order-3">
                                    <Fade bottom cascade duration={3000}>
                                        <div className="content-right text-center text-md-left text-md-left">
                                            <span className="desc">SocialFi platform enables the published content to be tokenized, allowing users or digital creators to earn income from their content.</span>
                                        </div>
                                    </Fade>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="container py-5" id="tokenomic">
                        <div className="row align-items-center justify-content-center mt-3" >
                            <div className="col-md-1 col-2 text-right">
                                {/* <img src={require('../assets/img/smallcube.png').default} className="img-fluid" /> */}
                            </div>
                            <div className="col-md-3 col-6 text-center" >
                                <span className="title text-uppercase">TOKENOMIC</span>
                            </div>
                            <div className="col-md-1 col-2 text-left">
                                {/* <img src={require('../assets/img/smallcube.png').default} className="img-fluid" /> */}
                            </div>
                        </div>
                        <Row className="justify-content-center mt-4 py-5">
                            <Col xl={tokenSelect === 'cat' ? 6 : 3} md={tokenSelect === 'cat' ? 6 : 4} sm={12} xs={12} className="p-0 mb-2 token-column" id="token">
                                <div className="pt-4 pb-2 px-4 mx-2 h-100 token-column-inner">
                                    <div className={tokenSelect === 'cat' ? "ml-4 mr-4 mt-2 mb-2" : 'm-0'}>
                                        <Row className="justify-content-center align-items-center">
                                            <Col xl={tokenSelect === 'cat' ? 6 : 12} md={tokenSelect === 'cat' ? 6 : 12} sm={12} xs={12} className="text-center">
                                                <img src={require('../assets/img/users/catcoin.png').default} className="img-fluid" />
                                            </Col>
                                            <Col xl={6} md={12} sm={12} xs={12} className={`text-center text-md-left text-md-left tokonomic-details ${tokenSelect === 'cat' ? 'active' : ''}`}>
                                                <ul>
                                                    <li>Governance Token</li>
                                                </ul>
                                                <span className="token-desc">CAT is the powering the CATX Cube ecosystem. Transaction tokens and revenue aggregators for generating extra rewards.</span>
                                                <div className="text-center py-2">
                                                    {/* <div className="flow-border">
                                                        <span className="flow-desc">Crypto Exchange</span>
                                                    </div>
                                                    <img src={require('../assets/img/arrow-bottom.png').default} className="img-fluid" />
                                                    <div className="flow-border">
                                                        <span className="flow-desc">Governance</span>
                                                    </div>
                                                    <img src={require('../assets/img/arrow-bottom.png').default} className="img-fluid" />
                                                    <div className="flow-border">
                                                        <span className="flow-desc">NFTS</span>
                                                    </div> */}
                                                    <img src={require('../assets/img/users/cat_box.png').default} className="img-fluid" />

                                                </div>
                                            </Col>
                                        </Row>
                                        <div className="bottom-text">
                                            <Row className="align-items-center py-2">
                                                <Col md={8} sm={10} xs={10}>
                                                    <span className="coinName">CAT</span>
                                                </Col>
                                                <Col md={4} sm={2} xs={2} className="text-right">
                                                    {tokenSelect === 'cat' ? (
                                                        <img src={require('../assets/img/minimize.png').default} className="img-fluid" onClick={() => setTokenSelect(null)} />
                                                    ) : (
                                                        <img src={require('../assets/img/expand.png').default} className="img-fluid" onClick={() => setTokenSelect('cat')} />
                                                    )}
                                                </Col>
                                            </Row>
                                        </div>
                                    </div>
                                </div>
                            </Col>


                            <Col xl={tokenSelect === 'fox' ? 6 : 3} md={tokenSelect === 'fox' ? 6 : 4} sm={12} xs={12} className="p-0 mb-2 token-column" id="token">
                                <div className="pt-4 pb-2 px-4 mx-2 h-100 token-column-inner">
                                    <div className={tokenSelect === 'fox' ? "ml-4 mr-4 mt-2 mb-2" : 'm-0'}>
                                        <Row className="justify-content-center align-items-center">
                                            <Col xl={tokenSelect === 'fox' ? 6 : 12} md={tokenSelect === 'fox' ? 6 : 12} sm={12} xs={12} className="text-center">
                                                <img src={require('../assets/img/users/foxcoin.png').default} className="img-fluid" />
                                            </Col>
                                            <Col xl={6} md={12} sm={12} xs={12} className={`text-center text-md-left text-md-left tokonomic-details ${tokenSelect === 'fox' ? 'active' : ''}`}>
                                                <ul>
                                                    <li>Utility Token</li>
                                                </ul>
                                                <span className="token-desc">Fox is a secondary CUBE platform
                                                    built on the BNB chain (BEP-20), in
                                                    addition to the main governance
                                                    token (CAT) that is primarily used to
                                                    power the CUBE ecosystem. Create
                                                    and watch-to-earn to get a reward
                                                    on the CUBE box.</span>
                                                <div className="text-center py-2">
                                                    {/* <div className="flow-border">
                                                        <span className="flow-desc">Social</span>
                                                    </div>
                                                    <img src={require('../assets/img/arrow-bottom.png').default} className="img-fluid" />
                                                    <div className="flow-border">
                                                        <span className="flow-desc">E-Commerce</span>
                                                    </div>
                                                    <img src={require('../assets/img/arrow-bottom.png').default} className="img-fluid" />
                                                    <div className="flow-border">
                                                        <span className="flow-desc">Creator</span>
                                                    </div> */}
                                                    <img src={require('../assets/img/users/fox_box.png').default} className="img-fluid" />
                                                </div>
                                            </Col>
                                        </Row>
                                        <div className="bottom-text">
                                            <Row className="align-items-center py-2">
                                                <Col md={8} sm={10} xs={10}>
                                                    <span className="coinName">FOX</span>
                                                </Col>
                                                <Col md={4} sm={2} xs={2} className="text-right">
                                                    {tokenSelect === 'fox' ? (
                                                        <img src={require('../assets/img/minimize.png').default} className="img-fluid" onClick={() => setTokenSelect(null)} />
                                                    ) : (
                                                        <img src={require('../assets/img/expand.png').default} className="img-fluid" onClick={() => setTokenSelect('fox')} />
                                                    )}
                                                </Col>
                                            </Row>
                                        </div>
                                    </div>
                                </div>
                            </Col>
                        </Row>
                    </div>
                    <div className="container-fluid py-5" id="create2earn">
                        <div className="container">
                            <div className="floatObj2">
                                <img src={require('../assets/img/users/floatObject2.png').default} className="img-fluid floating2" />
                            </div>
                            <Row className="align-items-center justify-content-center flex-lg-row flex-md-row flex-column-reverse" id="createearn">
                                <Col md={6} sm={12} xs={12} className="order-xs-2">
                                    <Slide left duration={2000}>
                                        <div className="row align-items-center justify-content-center mt-3 mb-3">
                                            <div className="col-xl-1 col-md-2 col-2 text-right">
                                                {/* <img src={require('../assets/img/smallcube.png').default} className="img-fluid" /> */}
                                            </div>

                                            <div className="col-xl-10 col-md-8 col-8 text-center">
                                                <span className="title text-uppercase">Create 2 earn</span>
                                            </div>
                                            <div className="col-xl-1 col-md-2 col-2 text-left">
                                                {/* <img src={require('../assets/img/smallcube.png').default} className="img-fluid" /> */}
                                            </div>
                                        </div>
                                        <div className="text-center text-md-left text-md-left">
                                            <span className="desc">CUBE allows talent who love video creation to generate a decent income in the form of NFTs (FOX token) in exchange for their video-making efforts.</span>
                                            <span className="desc">Our platform provides global audiences within the crypto community, which gives you a satisfactory video view count and earns more token rewards. Upload videos and share your talent to reach a greater audience and gain maximum profits.</span>
                                        </div>
                                    </Slide>
                                </Col>
                                <Col md={6} sm={12} xs={12} className="order-xs-1 text-center">
                                    <Slide left duration={2000}>
                                        <img src={require('../assets/img/users/method1.png').default} className="img-fluid" />
                                    </Slide>
                                </Col>
                            </Row>
                        </div>
                    </div>
                    <div className="container-fluid" id="watch2earn">
                        <div className="container py-5">
                            <div className="floatObj3">
                                <img src={require('../assets/img/users/floatObject3.png').default} className="img-fluid floating2" />
                            </div>
                            <Row className="align-items-center justify-content-center">
                                <Col md={6} sm={12} xs={12} className="text-center">
                                    <Fade left duration={3000}>
                                        <img src={require('../assets/img/users/method2.png').default} className="img-fluid" />
                                    </Fade>
                                </Col>
                                <Col md={6} sm={12} xs={12}>
                                    <Fade top duration={3000}>
                                        <div className="row align-items-center justify-content-center mt-3 mb-3">
                                            <div className="col-xl-1 col-md-2 col-2 text-right">
                                                {/* <img src={require('../assets/img/smallcube.png').default} className="img-fluid" /> */}
                                            </div>
                                            <div className="col-xl-10 col-md-8 col-8 text-center">
                                                <span className="title text-uppercase">watch 2 earn</span>
                                            </div>
                                            <div className="col-xl-1 col-md-2 col-2 text-left">
                                                {/* <img src={require('../assets/img/smallcube.png').default} className="img-fluid" /> */}
                                            </div>
                                        </div>
                                        <div className="text-center text-md-left text-md-left">
                                            <span className="desc">While most regular live streaming platforms benefit content creators and investors, CUBE takes care of each audience too. </span>
                                            <span className="desc">The Watch-to-Earn model allows users to earn FOX token rewards as they watch videos published on the CUBE platform. Use your free time to watch as much video as you can and catch more NFTs.</span>
                                        </div>
                                    </Fade>
                                </Col>
                            </Row>
                        </div>
                    </div>
                </div>
                <div className="container-fluid px-0" id="creators">
                    <div className="content-container">
                        <div className="floatObj1">
                            <img src={require('../assets/img/creators/item1.png').default} className="img-fluid floating2" />
                        </div>
                        <div className="floatObj2">
                            <img src={require('../assets/img/creators/item3.png').default} className="img-fluid floating2" />
                        </div>
                        <div className="floatObj3">
                            <img src={require('../assets/img/creators/item2.png').default} className="img-fluid floating2" />
                        </div>
                        <div className="container py-5">
                            <Flip top cascade duration={1000}>
                                <div className="row align-items-center justify-content-center">
                                    <div className="col-xl-1 col-md-2 col-2 text-right">
                                        {/* <img src={require('../assets/img/smallcube.png').default} className="img-fluid" /> */}
                                    </div>
                                    <div className="col-xl-5 col-md-5 col-8 text-center">
                                        <h1 className="title text-uppercase">Creators</h1>
                                    </div>
                                    <div className="col-xl-1 col-md-2 col-2 text-left">
                                        {/* <img src={require('../assets/img/smallcube.png').default} className="img-fluid" /> */}
                                    </div>
                                </div>
                            </Flip>
                            <div className="row align-items-center justify-content-center mt-3">
                                <div className="col-xl-10 col-md-10 col-12 text-center">
                                    <span className="main-description">CUBE SOCIAL APP is built as a platform where our community members can come together, interact, share experiences, shop for their favourite products, watch their favourite content, and also attend exclusive VIP experiences.</span>
                                    <span className="main-description">On our platform, creators will be able to collaborate and share content with more deliberate and enhanced audience experience through engagements, LIVE streams, chat funtions, with a range of built-in editing tools to simplify the content creation process for our users. They will be able to grow their influence while earning tokens with their content.</span>
                                </div>
                            </div>
                            <div className="container community">
                                <div className="floatObj4">
                                    <img src={require('../assets/img/creators/item4.png').default} className="img-fluid floating2" />
                                </div>
                                <Row className="align-items-center justify-content-center">
                                    <Col xl={6} md={6} sm={12} xs={12}>
                                        <div className="floatObj5">
                                            <img src={require('../assets/img/creators/item5.png').default} className="floating2 img-fluid" />
                                        </div>
                                        <Fade left cascade duration={1000}>
                                            <div className="row align-items-center justify-content-center">
                                                <div className="col-xl-1 col-md-2 col-2 text-right">
                                                    {/* <img src={require('../assets/img/smallcube.png').default} className="img-fluid" /> */}
                                                </div>
                                                <div className="col-xl-10 col-md-8 col-8 text-center">
                                                    <h1 className="community-header text-uppercase mb-0">COMMUNITY<br />GUIDELINES</h1>
                                                </div>
                                                <div className="col-xl-1 col-md-2 col-2 text-left">
                                                    {/* <img src={require('../assets/img/smallcube.png').default} className="img-fluid" /> */}
                                                </div>
                                            </div>
                                        </Fade>
                                    </Col>
                                    <Col xl={6} md={6} sm={12} xs={12} className="text-center">
                                        <Fade right duration={2000}>
                                            <span className="community-desc">Our goal is to maintain a welcoming environment for everyone, and foster positive, motivating, and diverse communities within the ecosystem. We provide the following guidelines to protect and safekeep the integrity and principles of our platform, and communities. These guidelines apply to all user generated content and activity on our services.</span>
                                        </Fade>
                                    </Col>
                                </Row>
                                <div className="floatObj6">
                                    <img src={require('../assets/img/creators/item6.png').default} className="img-fluid floating2" />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <DarkFooter />
        </>
    );
}

export default Cube;
